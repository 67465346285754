import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { DatePicker, notification, Select } from 'antd';
import { AES } from 'crypto-js';

import { getCustomerCardsService, createCustomerCardService, markPrimaryCard,  deleteCardService, additionalCharges, } from '../../services/programService';

import { getitemsincart } from '../../store/cart';
import { billingDetailService } from '../../services/otherService';
import settings from '../../config/settings';
import { ProgramLoader } from '../reuse/SVG';

const { Option } = Select;


const addZero = num => {
    if( num.toString().length < 2 )
    return "0" + num;
    return num.toString();
}


const getCardTypeName = type => {
    switch (type) {
        case 'American Express':
            return 'AmericanExpress';
            break;
        case 'Visa Electron':
        case 'visa':
            return 'Visa';
            break;
        case 'mastercard':
        case 'Mastercard':
            return 'MasterCard';
            break;
        case 'discover':
        case 'Discover':
            return 'Discover';
            break;
        case 'china_unionpay':
        case 'ChinaUnionpay':
            return 'Unionpay';
            break;
        case 'Diners Club':
            return 'DinersClub';
            break;
        default:
            return 'Visa';
    }
}



const ChargedPaymentGateway = props => {
    const [btnloder, setBtnloder] = useState(false);


    const [paymentMethod, setPaymentMethod] = useState(0);
    const [paymentMethodErr, setPaymentMethodErr] = useState('');
    const [isNewCard, setIsNewCard] = useState(false);
    const [cardList, setCardList] = useState([]);


    const [addCardBtnLoader, setAddCardBtnLoader] = useState(false);
    const [cardNo, setCardNo] = useState('');
    const [cardNoErr, setCardNoErr] = useState('');
    const [expDate, setExpDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [expDateErr, setExpDateErr] = useState('');
    const [cvc, setCvc] = useState('');
    const [cvcErr, setCvcErr] = useState('');
    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastName, setLastName] = useState('');
    const [resErr, setResErr] = useState('');

    const [customerPaymentProfileId, setCustomerPaymentProfileId] = useState('');
    const [customerPaymentProfileIdErr, setCustomerPaymentProfileIdErr] = useState('');


    const [bankAccountType, setBankAccountType] = useState('');
    const [bankAccountNum, setBankAccountNum] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [nameOnAccount, setNameOnAccount] = useState('');
    const [bankAccountTypeErr, setBankAccountTypeErr] = useState('');
    const [bankAccountNumErr, setBankAccountNumErr] = useState('');
    const [routingNumberErr, setRoutingNumberErr] = useState('');
    const [nameOnAccountErr, setNameOnAccountErr] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [billingDetail, setBillingDetail] = useState({});
    const [zipCode, setZipCode] = useState('');
    const [zipCodeErr, setZipCodeErr] = useState('');
    const [primaryCardId,setPrimaryCardId] = useState('');
    const [isDeletePopup,setIsDeletePopup] = useState(false);
    const [deleteCardToken,setDeleteCardToken] = useState('');
    const [addPrimary, setAddPrimary] = useState(false);
    const [isSwitchPrimaryCard,setIsSwitchPrimaryCard] = useState(false);
    const [holdPrimaryId, setHoldPrimaryId] = useState('');
    const [loader, setLoader] = useState(true);


      
    useEffect(() => {
        setTotalAmount(props?.AllData?.amount);
        if(billingDetail){
            billingDetailService().then(res => {
            if (res.data.statusCode) {
                setBillingDetail(res.data.responseData.billingDetail)
            }
        })
    }

    }, [props?.AllData?.amount])




    const getCards = () => {
        setLoader(true)
        setCardList([]);
        setHoldPrimaryId('');
        setPrimaryCardId('');
        let query = `?isWeb=1&userId=${props.userId}&campCode=${props.userProfile.campCode}`
 
        getCustomerCardsService(query).then(res => {
            if (res.data.statusCode) {
                if(res.data.responseData.result.length){
                    setCardList(res.data.responseData.result);
                    setLoader(false)
                }else{
                    setLoader(false)
                    setIsNewCard(true);
                }
            } else {
                setLoader(false)
                setCardList([]);
            }
        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            setLoader(false)
        })
    }

    useEffect(() => {
       
        if(props?.AllData?.amount){
            getCards();
        }
      
    }, [props.isNewCardAdd ])




    const handleAddCardSubmit = () => {
        let validate = true;
    

        if(cardNo == ''){
            setCardNoErr('card number error');
            validate = false;
        }else if (!(cardNo.length == 18 || cardNo.length == 19)) {
            setCardNoErr('card number lenth error');
            validate = false;    
        }else if(cardNo.includes('_')){
            setCardNoErr('card number lenth error');
            validate = false;    
        }

        if(expDate == ''){
            setExpDateErr('card expired date error');
            validate = false;
        }

        if(zipCode == ''){
            setZipCodeErr('zip code error');
            validate = false;
        }

        if(cvc == ''){
            setCvcErr('cvc error');
            validate = false;
        }else if(cvc.includes('_')){
            setCvcErr('cvc lenth error');
            validate = false;    
        }

        if(firstName == ''){
            setFirstNameErr('first name error');
            validate = false;
        }else if(firstName.length > 50){
            setFirstNameErr('First name is to large max limit 50')
            validate = false;
        }

        if(validate){

            setAddCardBtnLoader(true);

            let _campCodes =  props?.userProfile?.campCode;
    
          
            let _cardNo = cardNo.replace(/\s/g,'');
            const encrypted_cardNo = AES.encrypt(_cardNo, settings.api.mySecretKey).toString();
            const encrypted_cvc = AES.encrypt(cvc, settings.api.mySecretKey).toString();

            let params = {
                cardNo: encrypted_cardNo,
                expDate,
                month,
                year,
                cvc: encrypted_cvc,
                firstName,
                lastName,
                email: props.userEmail || props.userProfile.email,
                isWeb: 1,
                campCode: _campCodes,
                zipCode,
                primary:String(addPrimary),
            }

            if(props.userId){
                params.userId = props.userId
            }

           
            createCustomerCardService(params).then(res => {
                setAddCardBtnLoader(false);
                if(res?.data?.statusCode){
                    setCardNo('');
                    setExpDate('');
                    setCvc('');
                    setFirstName('');
                    setLastName('');
                    setIsNewCard(false);
                    getCards();
                }else{
                    setResErr(res.data.error.responseMessage)
                }
                
            })
        }
    }









    const handleValidate = () => {
        let validate = true;

        if(paymentMethod == 0){
            validate = false;
            setPaymentMethodErr('Please select payment method');
        }

        if(paymentMethod == 1) {
            if(customerPaymentProfileId == ''){
                validate = false;
                setCustomerPaymentProfileIdErr('Please select a card')
            }
        }

        if(paymentMethod == 3) {

            if(bankAccountType == '' || bankAccountType == 'undefined' || bankAccountType == null){
                validate = false;
                setBankAccountTypeErr('Account type is required')
            }

            if(bankAccountNum == '' || bankAccountNum == 'undefined' || bankAccountNum == null){
                validate = false;
                setBankAccountNumErr('Account number is required')
            }

            if(routingNumber == '' || routingNumber == 'undefined' || routingNumber == null){
                validate = false;
                setRoutingNumberErr('Routing number is required')
            }

            if(nameOnAccount == '' || nameOnAccount == 'undefined' || nameOnAccount == null){
                validate = false;
                setNameOnAccountErr('Account holder name is required')
            }

        }
       

        return validate;
    }





    const submitRegistration = () => {
        setResErr('');
        if (handleValidate()){
            setBtnloder(true);
            let payment = {};

            let params = {
                isWeb: 1,
                amount: totalAmount,
                userId: props.userId,
                billingDetail: JSON.stringify(billingDetail),
                bookingId: props?.AllData?.bookingId,
                programId:props?.AllData?.programId,
                camperId:props?.AllData?.selectedCamper,
                description:props?.AllData?.description,
                bookingNumber:props?.AllData?.bookingNumber,
            };

            


            if(paymentMethod == 1) {
                params.payment = JSON.stringify({
                    method: 'Credit Card',
                    amount: totalAmount
                });
                params.authPayment = JSON.stringify({
                    paymentType: 1,
                    customerPaymentProfileId,
                    token: customerPaymentProfileId
                });
            }





            if(paymentMethod == 3) {
                params.payment = JSON.stringify({
                    method: 'Internet Banking',
                    amount: totalAmount
                });
                params.authPayment = JSON.stringify({
                    paymentType: 1,
                    customerPaymentProfileId,
                    token: customerPaymentProfileId
                });


                const __routing_number = AES.encrypt(routingNumber, settings.api.mySecretKey).toString();
                const __bankAccountNum = AES.encrypt(bankAccountNum, settings.api.mySecretKey).toString();


                params.internetBanking = 1;
                params.authPayment = JSON.stringify({
                    account_owner_name: nameOnAccount,
                    routing_number: __routing_number,
                    account_number: __bankAccountNum,
                    account_type: bankAccountType,
                    account_owner_type: 'individual',
                    country: 'US',
                    currency: 'usd',
                    paymentType: 4
                });
            }

           
    
            additionalCharges(params).then(res => {
                setBtnloder(false);
                if (res.data.statusCode === 1) {
                    props.callback()
                    notification.success({
                        description: "Charges Added Succesfully.",
                    })
                }else{
                    setResErr(res.data.error.responseMessage)
                    props.callback()
                    notification.error({
                        description: "Failed to add charges.",
                    })
                }
            }).catch((e) => {
                setBtnloder(false);
                console.log(e)
            })

        } else {
            setBtnloder(false);
   
        }
    }


    const handleCardNumberChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCardNo(formattedNumber);
        setCardNoErr('');
      };

    const handleCvcChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCvc(formattedNumber);
        setCvcErr('');
      };

      const handleDeleteCard = (cardId) => {
        setIsDeletePopup(true); 
        setDeleteCardToken(cardId)
      }
      const deleteCardAction = () => {
        const params = {
            userId: props.userId,
            cardToken :deleteCardToken,
        }
        //added api call to remove the card using card id. 
        deleteCardService(params).then( res => {
            if(res.status){
                getCards()
                setCardList(cardList.filter(card =>card.card.id !== deleteCardToken));
                setIsDeletePopup(false)
            }
        }).catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsDeletePopup(false)
        }
        )
        
      }
      const handlePrimaryCardAction = () => {
        const params = {
            userId: props.userId,
            cardToken :holdPrimaryId,
        }
        markPrimaryCard(params).then( res => {
            if(res.status){
                setIsSwitchPrimaryCard(false)
                getCards();
                setPrimaryCardId(holdPrimaryId);
            }
        }).catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsSwitchPrimaryCard(false)
        }
        )
      }
      const handlePrimaryCard = (id) => {
            setHoldPrimaryId(id)
            setIsSwitchPrimaryCard(true)
      }


    return (
        <div className="payment-gateway pl-4 pr-4">
            <Modal
                    show={isDeletePopup || isSwitchPrimaryCard}
                    animation={false}
                    dialogClassName="folder_model"
                    size="lg"
                    centered
                >
                {isSwitchPrimaryCard && 
                    <Modal.Body>
                    <span className="modal_close" onClick={() => { setIsSwitchPrimaryCard(false) }}><i className="icon-cross" /></span>
                    <div className="text-center p-5 pickup_modal_body">
                        <p>Are you sure you want to change your primary card ?</p>
                    </div>
                    <div className="text-center p-5">
                        <button onClick={() => { setPrimaryCardId(''); setIsSwitchPrimaryCard(false) }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                        <button onClick={handlePrimaryCardAction} style={{ width: '120px' }} className="btn btn-primary ml-3">Yes</button>
                    </div>
                    </Modal.Body>
                
                }
                  { isDeletePopup && 
                    <Modal.Body>
                    <span className="modal_close" onClick={() => { setIsDeletePopup(false) }}><i className="icon-cross" /></span>
                    <div className="text-center p-5 pickup_modal_body">
                        <p>Are you sure you want to remove this card ?</p>
                    </div>
                    <div className="text-center p-5">
                        <button onClick={() => { setDeleteCardToken(''); setIsDeletePopup(false) }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                        <button onClick={deleteCardAction} style={{ width: '120px' }} className="btn btn-primary ml-3">Yes</button>
                    </div>
                    </Modal.Body>
                
                }
                </Modal>


                    <Fragment>
                        <h3>Select a Payment Option</h3>

                        <div className="row">

                            <div className="col-md-6">
                                <div
                                    className={`payment-gateway-card ${paymentMethod == 1 ? 'active' : ''}`}
                                    onClick={() => (setPaymentMethod(1), setPaymentMethodErr(''))}>
                                    <h4>Credit Card / Debit Card</h4>
                                    <h2>${totalAmount?.toFixed(2)}</h2>
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div
                                    className={`payment-gateway-card ${paymentMethod == 3 ? 'active' : ''}`}
                                    onClick={() => (setPaymentMethod(3), setPaymentMethodErr(''))}>
                                    <h4>Internet Banking</h4>
                                    <h2>${totalAmount?.toFixed(2)}</h2>
                                </div>
                            </div>


                            {/* {
                                props.userProfile.UserType != 1 ? totalAmount ? <div className="col-md-6">
                                    <div className={`payment-gateway-card ${paymentMethod == 6 ? 'active' : ''}`}
                                        onClick={() => (setPaymentMethod(6), setPaymentMethodErr(''))}>
                                        <h4>Pay Later</h4>
                                        <h2>${totalAmount.toFixed(2)}</h2>
                                    </div>
                                </div> : '' : ''
                            } */}
        
                        </div>


                        {paymentMethodErr ? <p className="color_red">{paymentMethodErr}</p> : ''}
                        {
                            paymentMethod === 1 ? (
                                <Fragment>
                                    <ul className="card_list">
                                        {loader ? 
                                            <div className="program_loader"><ProgramLoader /></div>
                                            :
                                          cardList.length ? cardList.map(item => (
                                                <li key={item.signature} className={customerPaymentProfileId === item.card.token ? 'active' : ''} style={{justifyContent: 'space-between'}}>
                                                <div style={{ display: 'flex' ,alignItems: 'center'}}>
                                                    <div className="check_box_wrapper cm_dark_check pt-1">
                                                        <input
                                                            checked={customerPaymentProfileId === item.card.token}
                                                            onChange={() => (setCustomerPaymentProfileId(item.card.token), setCustomerPaymentProfileIdErr(''))}
                                                            type="radio"
                                                            name="gender"
                                                            value="male" />
                                                        <span className="check_box"><span></span></span>
                                                    </div>
                                                    <div className="p_card_img ml-2">
                                                        <img src={item?.card?.brand ? require(`../../assets/img/${getCardTypeName(item.card.brand)}.svg`) : require(`../../assets/img/Visa.svg`)} />
                                                    </div>
                                                   
                                                    <span className='primary-card-text'>********{item.card.acct_last_four}</span>
                                                </div>
                                                <div className='primary-card-content'>
                                                 { !(item.card?.primary) ?
                                                    <>
                                                         <div className="check_box_wrapper cm_dark_check">
                                                            <input
                                                                checked={false}
                                                                onClick={() => handlePrimaryCard(item.card.token)}
                                                                type="radio"
                                                            />
                                                            <span className="check_box"><span></span></span>
                                                         </div>
                                                        <span className='primary-card-text'>set as primary</span>
                                                    </>
                                                    :
                                                       <span className = {`primary-card-text btn-primary`}>Primary Card</span>
                                                }
                                                 <div className = ""><i onClick={() => handleDeleteCard(item.card.token)} className="icon-delete" style={ {fontSize: "24px"}}/></div>
                                                </div>
   
                                               </li>

                                            )) : ''
                                        }
                                    </ul>

                                    {customerPaymentProfileIdErr ? <p className="color_red text-center">{customerPaymentProfileIdErr}</p> : ''}



                                {
                                    isNewCard ? (
                                        <div className="card_inputs add_card_form">
                                            <div className="form-group">
                                                <label>Card Number</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${cardNoErr ? 'is-invalid' : ''}`}
                                                        name="cardNumber"
                                                        placeholder="XXXX XXXX XXXX XXXX"
                                                        value={cardNo}
                                                        maxLength={19}
                                                        onChange={handleCardNumberChange} />

                                                    {cardNoErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cardNoErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            <div className="row">
                                                <div className="col form-group">
                                                    <label>Expiry Date</label>
                                                    <DatePicker
                                                        className={`form-control ${expDateErr ? 'is-invalid' : ''}`}
                                                        format="MM/YY"
                                                        picker="month"
                                                        placeholder="MM/YY"
                                                        onKeyPress={() => setExpDateErr('')}
                                                        onChange={e => (setMonth(`${addZero(e._d.getMonth() + 1)}`), setYear(`${e._d.getFullYear().toString()}`), setExpDate(`${addZero(e._d.getMonth() + 1)}${e._d.getFullYear().toString()}`), setExpDateErr(''))} />

                                                    {expDateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{expDateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>
                                                <div className="col form-group">
                                                    <label>CVC</label>
                                                    {/* <InputMask
                                                        className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                        placeholder="XXX"
                                                        mask="999"
                                                        onKeyPress={() => setCvcErr('')}
                                                        onChange={e => setCvc(e.target.value)} /> */}

                                                    <input
                                                        type="text"
                                                        className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                        name="cvc"
                                                        placeholder="XXX"
                                                        value={cvc}
                                                        maxLength={4}
                                                        onChange={handleCvcChange} />

                                                        {cvcErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cvcErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label>Cardholder First Name</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${firstNameErr ? 'is-invalid' : ''}`}
                                                    placeholder="Cardholder First Name"
                                                    onKeyPress={() => setFirstNameErr('')}
                                                    onChange={e => setFirstName(e.target.value)} />

                                                    {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            <div className="form-group">
                                                <label>Cardholder Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cardholder Last Name"
                                                    onChange={e => setLastName(e.target.value)} />
                                            </div>

                                            <div className="form-group">
                                                <label>Zip Code</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${zipCodeErr ? 'is-invalid' : ''}`}
                                                    placeholder="Zip Code"
                                                    onChange={e => (setZipCode(e.target.value), setZipCodeErr(''))} />

                                                    {zipCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{zipCodeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                            <div className='form-group'>  
                                            <div className="check_box_wrapper cm_dark_check pt-1">
                                                    
                                                    <input
                                                        checked={addPrimary}
                                                        onChange={() => setAddPrimary(prev => !prev)}
                                                        type="checkbox"
                                                    />
                                                    <span className="check_box"><span></span></span>
                                                    <label>Make this card primary</label>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                            <span onClick={() => setIsNewCard(false)} className="btn btn-info rounded-0 w-100 mr-2">Cancel</span>
                                            <span onClick={handleAddCardSubmit} className={`btn btn-primary rounded-0 w-100  ${addCardBtnLoader ? 'btnLoader' : ''}`}>Add</span>
                                            </div>


                                            </div>
                                    ) : <button className="btn btn-primary mb-3" onClick={() => setIsNewCard(true)}>Add New Card</button>
                                } 
                            </Fragment>
                            ) : ''
                        }


    


                        {    
                            paymentMethod == 3 ? (
                                <Fragment>
                                    <div className="card_inputs add_card_form">

                                        <div className="form-group">
                                            <label>Select Account Type</label>
                                            <Select
                                                className={`form-control ${bankAccountTypeErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Account Type"
                                                onChange={e => (setBankAccountType(e), setBankAccountTypeErr(''))}>

                                                <Option value="checking">Checking</Option>
                                                <Option value="savings">Savings</Option>
                                                <Option value="businessChecking">business Checking</Option>

                                            </Select>

                                            {bankAccountTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountTypeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        <div className="form-group">
                                            <label>Routing Number</label>
                                            <input
                                                type='number'
                                                className={`form-control ${routingNumberErr ? 'is-invalid' : ''}`}
                                                value={routingNumber}
                                                placeholder="Routing Number"
                                                name="routingNumber"
                                                onChange={e => (setRoutingNumber(e.target.value), setRoutingNumberErr(''))} />

                                            {routingNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{routingNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>

                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <input
                                                type='number'
                                                name="bankAccountNum"
                                                className={`form-control ${bankAccountNumErr ? 'is-invalid' : ''}`}
                                                value={bankAccountNum}
                                                placeholder="Account Number"
                                                onChange={e => (setBankAccountNum(e.target.value), setBankAccountNumErr(''))} />

                                            {bankAccountNumErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountNumErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>





                                        <div className="form-group">
                                            <label>Account Holder Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${nameOnAccountErr ? 'is-invalid' : ''}`}
                                                name="nameOnAccount"
                                                value={nameOnAccount}
                                                onChange={e => (setNameOnAccount(e.target.value), setNameOnAccountErr(''))}
                                                placeholder="Account Holder Name" />

                                            {nameOnAccountErr? <OverlayTrigger placement="left" overlay={<Tooltip>{nameOnAccountErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>




                                        </div>


                                        
                            </Fragment>
                            ) : ''
                        }
                    </Fragment>


                    {resErr ? <p className="error_msg">{resErr}</p> : ''}



            { !(paymentMethod ===0)  && 
                <div className="text-center mb-4 mt-4">
                <span onClick={() => btnloder ? null : submitRegistration()} className={`btn btn-primary  ${btnloder ? 'btnLoader' : ''}`} >Add Charges</span> 
            </div>
            }

        </div>
    )
}


const mapStateToProps = state => {
    let { loggedIn, userProfile } = state.user;
    let { isNewCardAdd } = state.ui;
    let itemsincart = state.cartReducer.cartitems;
    return {
        loggedIn,
        isNewCardAdd,
        itemsincart,
        userProfile
    }
}

const mapDispatchToProps = dispatch => ({
    getitemsincart: params => dispatch(getitemsincart(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChargedPaymentGateway);

