import moment from 'moment';
import InputMask from 'react-input-mask';
import ReactSelect from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { DatePicker, Select, notification } from 'antd';
import { AES } from 'crypto-js';
import _ from "lodash";
import axios from "axios";
import { validatecoupon, getCustomerCardsService2, createCustomerCardService, validateSlotsService } from '../../services/programService';
import { billingDetailService } from '../../services/otherService';
import { createBookingByAdminService, calcAllPriceService } from '../../services/otherService';
import { calculateEMIPaymentDates, provinceList, resHandle, hasSplitOption, getCardTypeImage, findFirstState } from '../../helper';
import { getCampDetailService } from '../../services/userServices';
import { InformationIcon } from '../reuse/SVG';
import settings from '../../config/settings';
import { CustomNumber, CustomTextarea } from '../reuse/FormControls';
import RegistrationCancellation from '../reuse/Insurance/RegistrationCancellation';
import GapMedicalInsurance from '../reuse/Insurance/GapMedical';
import { prepareQuoteData } from './bookingHelper';


const { Option } = Select;

const addZero = num => {
    if( num.toString().length < 2 )
    return "0" + num;
    return num.toString();
}

const getCardTypeName = type => {
    switch (type) {
        case 'American Express':
            return 'AmericanExpress';
            break;
        case 'Visa Electron':
        case 'visa':
            return 'Visa';
            break;
        case 'mastercard':
        case 'Mastercard':
            return 'MasterCard';
            break;
        case 'discover':
        case 'Discover':
            return 'Discover';
            break;
        case 'china_unionpay':
        case 'ChinaUnionpay':
            return 'Unionpay';
            break;
        case 'Diners Club':
            return 'DinersClub';
            break;
        default:
            return 'Visa';
    }
}


const getWithTaxPrice = (obj, amount) => {
    let withTax = 0
    if(obj.percentage){
        withTax = amount + ((amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        withTax = amount + obj.taxRate;
    }
    return withTax.toFixed(2);
}

const getInsuranceWithTaxPrice = (obj, amount) => {
    let withTax = 0
    if(obj.percentage){
        withTax = amount + ((amount * obj.percentage) / 100);
    }else{
        withTax = amount;
    }
    return withTax.toFixed(2);
}


const getSplitPrice = (obj, amount) => {
    let _amount = amount / obj.noOfTimesPayment;
    let withTax = 0
    if(obj.percentage){
        withTax = _amount + ((_amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        withTax = _amount;
    }
    return withTax.toFixed(2);
}

const getSplitPrice2 = (obj, amount) => {
    let _amount = amount / (obj.noOfTimesPayment || 1);
    return _amount.toFixed(2);
}



const getDatesPrices = (_dates, _selectedDates, _campers) => {
    let _price = 0;
    for (let i = 0; i < _selectedDates.length; i++) {
        _price += _dates.find(itm => itm._id == _selectedDates[i]).standardPrice
    } 
    return _price * _campers.length;
}

  
const dataLayer = window.dataLayer;




const PaymentInformationCompoant = ({programs, userId, handleBack, userEmail, userName, userLastName, camperList}) => {
    const history = useHistory();
    const [programStartDate, setProgramStartDate] = useState(programs[0].startDate);
    const [allPrograms, setAllPrograms] = useState(programs);
    const [couponCode, setCouponCode] = useState('');
    const [couponCodeLoader, setCouponCodeLoader] = useState(false);
    const [couponCodeErr, setCouponCodeErr] = useState('');
    const [couponCodeSuccess, setCouponCodeSuccess] = useState('');
    const [couponMoney, setCouponMoney] = useState(0);
    const [couponList, setCouponList] = useState([]);

    const [subtotal, setSubtotal] = useState(0);
    const [totalToPay, setTotalToPay] = useState(0);
    const [totalToPayOld, setTotalToPayOld] = useState(0);
    const [actualTotalPayAmount, setActualTotalPayAmount] = useState(0);
    const [confirmActualTotalPayAmount, setConfirmActualTotalPayAmount] = useState(false)

    const [paymentMethod, setPaymentMethod] = useState(0);
    const [paymentMethodErr, setPaymentMethodErr] = useState('');
    const [isNewCard, setIsNewCard] = useState(false);
    const [cardList, setCardList] = useState([]);
    const [discountArr, setDiscountArr] = useState([]);


    const [addCardBtnLoader, setAddCardBtnLoader] = useState(false);
    const [cardNo, setCardNo] = useState('');
    const [cardNoErr, setCardNoErr] = useState('');
    const [expDate, setExpDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [expDateErr, setExpDateErr] = useState('');
    const [cvc, setCvc] = useState('');
    const [cvcErr, setCvcErr] = useState('');
    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [insuranceState, setInsuranceState] = useState('');
    const [insuranceRender, setInsuranceRender] = useState(false);

    const [customerProfileId, setCustomerProfileId] = useState([]);
    const [customerPaymentProfileId, setCustomerPaymentProfileId] = useState('');
    const [customerPaymentProfileIdErr, setCustomerPaymentProfileIdErr] = useState('');
    const [customerPaymentProfileIdError, setCustomerPaymentProfileIdError] = useState('');

    const [billingDetail, setBillingDetail] = useState({});
    const [resErr, setResErr] = useState('');
    const [allCampCodes, setAllCampCodes] = useState('');

    const [bankAccountType, setBankAccountType] = useState('');
    const [bankAccountNum, setBankAccountNum] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [nameOnAccount, setNameOnAccount] = useState('');
    const [bankAccountTypeErr, setBankAccountTypeErr] = useState('');
    const [bankAccountNumErr, setBankAccountNumErr] = useState('');
    const [routingNumberErr, setRoutingNumberErr] = useState('');
    const [nameOnAccountErr, setNameOnAccountErr] = useState('');

    const [creditCardRate, setCreditCardRate] = useState({});
    const [achTransferRate, setAchTransferRate] = useState({});
    const [byCheque, setByCheque] = useState({});
    const [splitPaymentRate, setSplitPaymentRate] = useState({});

    const [validateSlots, setValidateSlots] = useState([]);
    const [isSlotAvailable, setIsSlotAvailable] = useState(false);
    const [slotAvailableMsg, setSlotAvailableMsg] = useState('');

    const [acceptedInsuranceQuotes, setAcceptedInsuranceQuotes] = useState([]);
    const [declinedInsuranceQuotes, setDeclinedInsuranceQuotes] = useState([]);
    const [insuranceTotal, setInsuranceTotal] = useState(0);
    const [zipCode, setZipCode] = useState('');
    const [zipCodeErr, setZipCodeErr] = useState('');
    const [rightNowAmount, setRightNowAmount] = useState(0);
    const [showInsurance, setShowInsurance] = useState(false)
    const [isInsuranceStateSelected, setIsInsuranceStateSelected] = useState(false);
    const [finalPayReason, setFinalPayReason] = useState('');
    const [finalPayReasonErr, setFinalPayReasonErr] = useState('');    
    const [payNote, setPayNote] = useState(false);
    const [insuranceComponentDelay, setInsuranceComponentDelay] = useState(false)

    useEffect(() => {
        const insuranceTimeoutId =  setTimeout(() => {
            setInsuranceComponentDelay(true)
        }, 300)
        return () => {
            clearTimeout(insuranceTimeoutId)
        }
    }, [])

    const calcAllPriceFun = () => {
        if(programs.length){
            let _programs = programs.map(i => ({
                programId: i._id,
                campCode: i.campCode,
                selectedDates: i.selectedDates || [],
                campers: i.campers.map(c => ({
                    camperId: c.camperId,
                    isCommuter: c.isCommuter,
                    additionalExperience: c.additionalExperience.filter(j => j.numberOfBooking),
                    answers: c.answers,
                }))
            }))
            let params = {
                'programs': _programs
            };

            calcAllPriceService(params).then(res => {
                if(res?.data?.statusCode){
                    let res_programs = res.data.responseData.programs;
                    let res_coupons = res.data.responseData.couponCodes;
                    if(res_coupons?.length){
                        setCouponList(res_coupons);
                    }

                    let _allPrograms = [...programs];
                    let finalPrograms = [];

                    for (let i = 0; i < res_programs.length; i++) {
                        let obj = _allPrograms.find(kk => kk.programId == res_programs[i].programId);
                        let newObj = Object.assign({}, res_programs[i], obj) 
                        finalPrograms.push(newObj);
                    }

                    



                    let _totalCost = 0;
                    let _allCampCodes = [];
                    let checkSlots = []
                    setEmail(finalPrograms);
                    for (let i = 0; i < finalPrograms.length; i++) {
                        _allCampCodes.push(finalPrograms[i].campCode);
                        checkSlots.push({
                            programId: finalPrograms[i].programId,
                            selectedDates: finalPrograms[i].selectedDates || [],
                            noOfBookings: finalPrograms[i].campers.length        
                        })

                        _totalCost = _totalCost + finalPrograms[i].f_totalPrice;
                        if(finalPrograms[i]._additionalDiscount){
                            _totalCost = _totalCost - finalPrograms[i]._additionalDiscount;
                        }

                        let _product_prices = 0;
                        for (let j = 0; j < finalPrograms[i].campers.length; j++) {
                            for (let k = 0; k < finalPrograms[i].campers[j].selectedProduct.length; k++) {
                                let _sub_items_price = 0;
                                _product_prices += (finalPrograms[i].campers[j].selectedProduct[k].count * finalPrograms[i].campers[j].selectedProduct[k].price)
                                if (finalPrograms[i].campers[j].selectedProduct[k]?.subItems?.length){
                                    _sub_items_price = finalPrograms[i].campers[j].selectedProduct[k]?.subItems?.reduce((tp, si) =>  tp + (si.count * si.price), 0)
                                }
                                _product_prices += _sub_items_price;                               
                            }
                        }

                        if(_product_prices){
                            _totalCost += _product_prices;
                        }

                        
                    }




                    setValidateSlots(checkSlots);
                    setAllCampCodes(Array.from(new Set(_allCampCodes)));
                    setSubtotal(_totalCost);
                    setTotalToPay(_totalCost);
                    setTotalToPayOld(_totalCost);
                    setActualTotalPayAmount(_totalCost);
                    setInsuranceState(findFirstState(programs))
                    setInsuranceRender(true)
                    setAllPrograms(finalPrograms)
                }else{
                    if(res.data.error.errorCode == 55){
                        setResErr('Participant Already Exists')
                    }
                }
            })


        }

       

    }


    const revicePayment = () => {
        if(isDifferentTotalPayAmount() && (finalPayReason === '' || finalPayReason === undefined || finalPayReason === null || isOnlySpaces(finalPayReason))){
            setFinalPayReasonErr('! Please add a payment change reason.');
            return;
        }
        setTotalToPay(actualTotalPayAmount);
        setConfirmActualTotalPayAmount(true);
        
    }

    useEffect(() => {
        if(programs.length){
            calcAllPriceFun();
        }
    }, [programs])




    const calculateProductFinalPrice  = (_product) => {
                let _product_prices = 0
                let _sub_items_price =0
                _product_prices += _product.count * _product.price;
                if (_product.subItems?.length){
                    _sub_items_price = _product.subItems?.reduce((tp, si) =>  tp + (si.count * si.price), 0)
                }
                _product_prices += _sub_items_price;
                return _product_prices                               
        }
        
    const getTaxs = () => {
        let query = '';
        if(programs?.length == 1){
            query = `?campCode=${allCampCodes[0]}&programId=${programs[0].programId}`;
        }else{
            query = `?campCode=${allCampCodes[0]}`;
        }
        getCampDetailService(query).then(res => {
            if(res?.data?.statusCode){
              let _data = res.data.responseData.result;
              setCreditCardRate(_data.creditCardRate || {});
              setAchTransferRate(_data.achTransferRate || {});
              setByCheque(_data.byCheque || {});
              setSplitPaymentRate(_data.splitPaymentRate || {});
              setShowInsurance(_data.showInsurance)
            }
        })
    }

    useEffect(() => {
        if(allCampCodes.length){
            getTaxs();
        }
      
    }, [allCampCodes])



    



    useEffect(() => {
        if(validateSlots.length){
            validateSlotsService({programData: JSON.stringify(validateSlots)}).then(res => {
                if(res.data.statusCode){
                    if(res.data.responseData.result == 1){
                        setIsSlotAvailable(true)
                    }else{
                        setIsSlotAvailable(false);
                        setSlotAvailableMsg(res.data.responseData.message)
                    }
                    
                }
            })
        }
    }, [validateSlots])



    const handleCouponCodeSubmit = () => {
        if(couponCode.value){
            setCouponCodeLoader(true);
            let noOfParticepentCount = 0;

            let newProgramsArr = [];
            let _additionalExperience = [];

            for (let i = 0; i < allPrograms.length; i++) {
                for (let j = 0; j < allPrograms[i].campers.length; j++) {
                    noOfParticepentCount = noOfParticepentCount + 1
                }
            }

            for (let i = 0; i < allPrograms.length; i++) {
                let newCampers = 0
                for (let j = 0; j < allPrograms[i].campers.length; j++) {
                    newCampers = newCampers + 1;
                    let _camperItem = allPrograms[i].campers[j]
                    if (_camperItem?.additionalExperience?.length) {
                        _additionalExperience = _camperItem?.additionalExperience.filter(jj => jj.numberOfBooking > 0);
                    }
                }


                newProgramsArr.push({
                    programId: allPrograms[i].programId,
                    programCost: allPrograms[i].f_campRegistrationFee + allPrograms[i].f_taxPrice,
                    additionalExperience: _additionalExperience || []
                })

                
            }

            let programId = JSON.stringify(allPrograms.map(i => i._id));

            let parms = {
                couponCode: couponCode.value,
                programId: programId,
                noOfParticepentCount: noOfParticepentCount,
                programData: JSON.stringify(newProgramsArr)
             }


            validatecoupon(parms).then(res => {
                setCouponCodeLoader(false);
                if (res.data.responseData.code == 1) {
                    let discountMoney = res.data.responseData.discountMoney;
                    let _discountArr = res.data.responseData.discountArr || [];
                    const discountMap = res.data.responseData.discountArr.reduce((acc, {amount, programId }) => {
                        acc[programId] = {exists: true, amount};
                        return acc;
                      }, {});
                      
                      const newPrograms = allPrograms.map(program => {
                        // Check if the current program's id is in the discountArr
                        if (discountMap[program.programId]?.exists) {
                            let _couponAmount = discountMap[program.programId].amount
                          const totalCampers = program.campers?.length || 1; // Fallback to 1 if no campers
                          const applicableCouponAmount = Math.round((_couponAmount / totalCampers) * 100) / 100;
                          // Update the campers with the applicableCouponAmount
                          const updatedCampers = program.campers?.map(cp => ({ ...cp, applicableCouponAmount })) || [];
                          // Return the updated program with the new campers array
                          return { ...program, campers: updatedCampers };
                        } 
                        // If no matching discount, return the program as is
                        return program;
                      });
                      setAllPrograms(newPrograms)
                    setCouponMoney(discountMoney);
                    setDiscountArr(_discountArr);
                    let _totalToPay = subtotal - discountMoney;//
                    if (discountMoney > totalToPay) {
                        _totalToPay = 0;
                        setTotalToPay(subtotal);
                        setActualTotalPayAmount(subtotal);
                    }
                    setTotalToPay(_totalToPay);
                    setActualTotalPayAmount(_totalToPay);
                    setCouponCodeSuccess('Coupon Applied Successfully')
                    setPayNote(false)
                    setFinalPayReason('')
                }else{
                    setCouponCodeErr('Invalid coupon Code');
                    setCouponCodeSuccess('');
                }
            })
        }else{
            setCouponCodeErr('Please enter valid coupon code');
            setCouponCodeSuccess('');
        }

    }


    const getCards = () => {
        let query = `?userId=${userId}&isWeb=1&campCode=${allCampCodes[0]}`
        getCustomerCardsService2(query).then(res => {
            if (res.data.statusCode) {
                setCardList(res.data.responseData.result ? res.data.responseData.result : []);
                if(res.data.responseData.result.length){
                    setCustomerProfileId(res.data.responseData.result ? res.data.responseData.result[0].card.signature : '');
                }
               
            } else {
                setCardList([]);
            }
        })
    }


    useEffect(() => {
        if(allCampCodes.length){
            getCards();
        }
     
    }, [allCampCodes])

    const handleActualTotalPayAmount = (e) => {
        if(totalToPayOld !== e){
            setPayNote(true);
        }
        setConfirmActualTotalPayAmount(false);
        setActualTotalPayAmount(e);
    }


    const getBillingDetail = () => {
        let query = `?userId=${userId}`;
        billingDetailService(query).then(res => {
            if (res.data.statusCode) {
                setBillingDetail(res.data.responseData.billingDetail)
                setIsInsuranceStateSelected(false);
            }
        })
    }


    useEffect(() => {
        if(userId){
            getBillingDetail()
        }
    }, [userId])



    const handleValidate = () => {
        let validate = true;

        if(totalToPay > 0){
            if(paymentMethod == 0){
                validate = false;
                setPaymentMethodErr('Please select payment method');
            }
    
            if(paymentMethod == 1 || (paymentMethod == 6 && rightNowAmount > 0)){
                if(customerPaymentProfileId == '' || customerPaymentProfileId == 'undefined' || customerPaymentProfileId == null){
                    validate = false;
                    setCustomerPaymentProfileIdError('Please select card')
    
                }
            }


            if(paymentMethod == 3) {

                if(bankAccountType == '' || bankAccountType == 'undefined' || bankAccountType == null){
                    validate = false;
                    setBankAccountTypeErr('Account type is required')
                }

                if(bankAccountNum == '' || bankAccountNum == 'undefined' || bankAccountNum == null){
                    validate = false;
                    setBankAccountNumErr('Account number is required')
                }

                if(routingNumber == '' || routingNumber == 'undefined' || routingNumber == null){
                    validate = false;
                    setRoutingNumberErr('Routing number is required')
                }

                if(nameOnAccount == '' || nameOnAccount == 'undefined' || nameOnAccount == null){
                    validate = false;
                    setNameOnAccountErr('Account holder name is required')
                }

            }
        }

       

        return validate;
    }






    const handleAddCardSubmint = () => {
        let validate = true;
        
        setResErr('');

        if(cardNo == ''){
            setCardNoErr('card number error');
            validate = false;
        }else if (!(cardNo.length == 18 || cardNo.length == 19)) {
            setCardNoErr('card number lenth error');
            validate = false;    
        }else if(cardNo.includes('_')){
            setCardNoErr('card number lenth error');
            validate = false;    
        }

        if(expDate == ''){
            setExpDateErr('card expired date error');
            validate = false;
        }

        if(zipCode == ''){
            setZipCodeErr('zip code error');
            validate = false;
        }

        if(cvc == ''){
            setCvcErr('cvc error');
            validate = false;
        }else if(cvc.includes('_')){
            setCvcErr('cvc lenth error');
            validate = false;    
        }

        if(firstName == ''){
            setFirstNameErr('first name error');
            validate = false;
        }else if(firstName.length > 50){
            setFirstNameErr('First name is to large max limit 50');
            validate = false;
        }

        if(validate){

            setAddCardBtnLoader(true);

            let _cardNo = cardNo.replace(/\s/g,'');
            const encrypted_cardNo = AES.encrypt(_cardNo, settings.api.mySecretKey).toString();
            const encrypted_cvc = AES.encrypt(cvc, settings.api.mySecretKey).toString();
            

          

            let params = {
                cardNo: encrypted_cardNo,
                expDate,
                month,
                year,
                cvc: encrypted_cvc,
                firstName,
                lastName,
                email: userEmail,
                userId: userId,
                isWeb: 1,
                campCode: allCampCodes,
                zipCode
                
                
            }

           
            createCustomerCardService(params).then(res => {
                setAddCardBtnLoader(false);
                if(res?.data?.statusCode){
                    getCards();
                    setCardNo('');
                    setExpDate('');
                    setCvc('');
                    setFirstName('');
                    setLastName('');
                    setIsNewCard(false);
                }else{
                    setResErr(res.data.error.responseMessage)
                }
                
            })
        }
    }


   const isDifferentTotalPayAmount = () => {
    if(couponMoney){
        return totalToPayOld !== actualTotalPayAmount + couponMoney;
    }
    return  (totalToPayOld !== actualTotalPayAmount);
   }
   const  isOnlySpaces = (str) =>  {
        return str.trim().length === 0;
   }


    const handleCreateBooking = () => {
            if(isDifferentTotalPayAmount() && (finalPayReason === '' || finalPayReason === undefined || finalPayReason === null || isOnlySpaces(finalPayReason))){
                setFinalPayReasonErr('! Please add a payment change reason.');
                return;
            }

        if (handleValidate()) {

            setBtnLoader(true);
            let payment = {};
            let params = {};

            let _totalToPay = totalToPay;

            if(actualTotalPayAmount && confirmActualTotalPayAmount){
                if(totalToPay > actualTotalPayAmount)
                    params.finalPayAmount = actualTotalPayAmount;
                    params.finalPayAmountReason = finalPayReason;
                _totalToPay = actualTotalPayAmount;
                
            }
            if(insuranceTotal){
                params.insurance = insuranceTotal

                let insuranceQuotes = acceptedInsuranceQuotes.concat(declinedInsuranceQuotes);
                if (insuranceQuotes.length > 0) {
                  params.insuranceQuotes = insuranceQuotes;
                } 
            }
           
            if (paymentMethod == 1) {
                payment.method = 'Credit Card';
                payment.amount = getWithTaxPrice(creditCardRate, _totalToPay);
                params.actualTotalPayAmount = totalToPayOld;
                params.finalPayAmount = confirmActualTotalPayAmount ? actualTotalPayAmount : (totalToPay + insuranceTotal);
                params.finalPayAmountReason = finalPayReason;
                payment.creditCardRate = {percentage: creditCardRate.percentage,taxRate:  creditCardRate.taxRate}
     
                params.authPayment = JSON.stringify({
                    paymentType: paymentMethod,
                    customerProfileId,
                    customerPaymentProfileId,
                    token: customerPaymentProfileId
                })
            }


            if (paymentMethod == 6) {
                if(rightNowAmount > 0){
                    payment.method = 'Credit Card';
                    payment.creditCardRate = {percentage: creditCardRate.percentage,taxRate:  creditCardRate.taxRate}
                }else{
                    payment.method = 'Pay Later';
                }
                
                params.totalAmount = getWithTaxPrice(creditCardRate, _totalToPay);
                params.actualTotalPayAmount = totalToPayOld
                //payment.amount = getWithTaxPrice(creditCardRate, rightNowAmount);
                payment.amount = (rightNowAmount > 0) ? getWithTaxPrice(creditCardRate, rightNowAmount) : 0;
                params.finalPayAmount = confirmActualTotalPayAmount ? actualTotalPayAmount : (totalToPay + insuranceTotal);
                params.finalPayAmountReason =  finalPayReason;
                params.remainingAmount = parseFloat((actualTotalPayAmount - (rightNowAmount || 0))?.toFixed(2))
     
                params.authPayment = JSON.stringify({
                    paymentType: paymentMethod,
                    customerProfileId,
                    customerPaymentProfileId,
                    token: customerPaymentProfileId
                })
            }

            if(actualTotalPayAmount > 0){
                params.processingFee = parseFloat((getWithTaxPrice(creditCardRate, _totalToPay) - _totalToPay)?.toFixed(2));
            }

            if(paymentMethod == 3) {
                payment.method = 'Internet Banking';
                payment.amount = getWithTaxPrice(achTransferRate, _totalToPay);
                params.finalPayAmount = confirmActualTotalPayAmount ? actualTotalPayAmount : (totalToPay + insuranceTotal);
                params.finalPayAmountReason =  finalPayReason;
                payment.actualTotalPayAmount = totalToPayOld;
                payment.achTransferRate = {percentage: achTransferRate.percentage,taxRate:  achTransferRate.taxRate}
                

                params.internetBanking = 1;

                const __routing_number = AES.encrypt(routingNumber, settings.api.mySecretKey).toString();
                const __bankAccountNum = AES.encrypt(bankAccountNum, settings.api.mySecretKey).toString();
                params.authPayment = JSON.stringify({
                    account_owner_name: nameOnAccount,
                    routing_number: __routing_number,
                    account_number: __bankAccountNum,
                    account_type: bankAccountType,
                    account_owner_type: 'individual',
                    country: 'US',
                    currency: 'usd',
                    paymentType: 4
                });
            }

            if(paymentMethod == 4) {
                payment.method = 'Check';
                payment.amount = getWithTaxPrice(byCheque, _totalToPay);
                params.finalPayAmount = confirmActualTotalPayAmount ? actualTotalPayAmount : (totalToPay + insuranceTotal);
                params.finalPayAmountReason =  finalPayReason;
                payment.status = 2;
                payment.byCheque = {taxRate:  byCheque.taxRate}
                params.authPayment = JSON.stringify({
                    paymentType: 5
                });
            }

            if(paymentMethod == 5) {
                payment.method = 'Split Payment';
                payment.amount = getSplitPrice(splitPaymentRate, _totalToPay);
                params.finalPayAmount = confirmActualTotalPayAmount ? actualTotalPayAmount : (totalToPay + insuranceTotal);
                params.finalPayAmountReason =  finalPayReason;
                params.authPayment = JSON.stringify({
                    paymentType: 1,
                    customerProfileId,
                    customerPaymentProfileId,
                    token: customerPaymentProfileId
                });
                payment.splitPaymentRate = {percentage: splitPaymentRate.percentage,taxRate:  splitPaymentRate.taxRate}
                let _allEmiRemains = calculateEMIPaymentDates(programs[0].startDate, (splitPaymentRate.noOfTimesPayment - 1), getSplitPrice2(splitPaymentRate, _totalToPay));

                params.remainingAmount = _totalToPay - getSplitPrice2(splitPaymentRate, _totalToPay);

                if(params.remainingAmount > 0){
                    params.remainingAmount = params.remainingAmount.toFixed(2)
                }


                params.isEmi = JSON.stringify({
                    status : 1,
                    month : splitPaymentRate.noOfTimesPayment,
                    remaingMonth: splitPaymentRate.noOfTimesPayment - 1,
                    allEmiRemains: _allEmiRemains
                })
            }

            params.isWeb = 1;
            params.userId = userId;
            params.cartIds = [];
            params.programs = allPrograms.map(_program => ({..._program, selectedDates: _program.selectedDates || []}));
            params.billingDetail = JSON.stringify(billingDetail);
            
            if(totalToPay){
                params.payment = JSON.stringify(payment);
            }


            if (couponMoney) {
                params.couponCode = couponCode.value;
                params.couponValue = couponMoney.toFixed(2);
                params.discountArr = discountArr;
            }
    
            createBookingByAdminService(params).then(res => {
                let { status, data } = resHandle(res);
                setBtnLoader(false);
                if(status){
                    
                    notification.open({
                        message: 'Booking',
                        description:
                            'Booking create successfully',
                    });
                    history.push('/bookings');

                    // dataLayer.push({
                    //     currency: "USD",
                    //     event: 'sport_international_checkout',
                    //     order_id: data.data.responseData.result.bookingNumber, 
                    //     value: data.data.responseData.result.payment.amount,
                    //     email: data.data.responseData.email,
                    //     offer_code: data.data.responseData.result.couponCode || ''
                    //  })
                    
                }
            })
        }  
        
    }

    const handleCardNumberChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCardNo(formattedNumber);
        setCardNoErr('');
      };


      const handleCvcChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCvc(formattedNumber);
        setCvcErr('');
      };

      const validateInsuranceOffers = async () => {
        const offers = document.querySelectorAll('multi-offer');
        let allValid = true; 
        
        for (const offer of offers) {
            if (offer.quotes && offer.quotes.length > 0) {
                const res = await offer.validate();
                if (!res.isValid) allValid = false;
            }
        }

        return allValid;
    }
    const updateInsuranceQuotes = (e) => {
        const timeOutId = setTimeout(() => {
                const offers = document.querySelectorAll('multi-offer');                    
                const _acceptedInsuranceOffers = [];
                const _declinedInsuranceOffers = [];
                let insuranceTotal = 0;

                    offers.forEach(offer => {
                        if (offer.quotes && offer.quotes.length > 0) {
                            offer.style.display = 'block';
                                offer.quotes.forEach(quoteDetails => {
                                    if (quoteDetails?.selectedState  && quoteDetails?.selectedState === 'ACCEPTED') {
                                        const quoteData = prepareQuoteData(quoteDetails, offer)
                                        quoteData.insuranceStatus = 1; 
                                        _acceptedInsuranceOffers.push(quoteData);
                                        insuranceTotal += (quoteDetails.quote.total / 100);
                                    }else{
                                        const quoteData = prepareQuoteData(quoteDetails, offer)
                                        _declinedInsuranceOffers.push(quoteData);
                                    }
                                });
                        } else {
                            offer.style.display = 'none';
                        }
                    });
                    setAcceptedInsuranceQuotes(_acceptedInsuranceOffers);
                    setDeclinedInsuranceQuotes(_declinedInsuranceOffers);
                    setInsuranceTotal(insuranceTotal);
                }, 10)
                return () => {
                    clearTimeout(timeOutId)
                }
     }

     const handlePayChangeReaon = (e) => {
        setFinalPayReason(e.target.value)
         setFinalPayReasonErr('')
     }

    return(
        <div>
            {allPrograms.map(item => (
                <div key={item._id} className="c_bor_b">
                    <div className='d-flex justify-content-between date_heading'>
                        <span></span>
                        <span>{item.campName} - {item.programName}</span>
                        {item?.byDates === 1 ?
                        item?.additionalDates.filter(ad => item?.selectedDates.includes(ad._id)).map((date, i )=> <p> {i > 0 && '+'} <small>( {moment(date.startDate).format('MM/DD/YYYY')} to {moment(date.endDate).format('MM/DD/YYYY')} )</small></p>) 
                        :
                        <span>{moment(item.startDate).format('MM/DD/YYYY')} to {moment(item.endDate).format('MM/DD/YYYY')}</span>
                        }
                    </div>

                    <div className='d-flex justify-content-end pr-4'>
                        <p>Total Cost:</p>
                        <b>${item.f_totalPrice?.toFixed(2)}</b>
                    </div>

                    


                    <div className='d-flex justify-content-end pr-4'>
                        <p>Program Cost:</p>
                        {
                            item.byDates == 2 ? <b>${(item.f_campRegistrationFee - item.f_registerNonRefundFee)?.toFixed(2)}</b> : <b>${(item.f_campRegistrationFee)?.toFixed(2)}</b>
                        }
                    </div>

                    {
                        item.byDates === 2 && item.f_registerNonRefundFee ?
                        <div className="d-flex justify-content-end pr-4">
                            <p>Non Refundable Registration Amount:</p>
                            <b>${item.f_registerNonRefundFee?.toFixed(2)}</b>
                        </div> : ''
                    }

                    
                    {
                        item.f_additionalTotal ? (
                            <div className='d-flex justify-content-end pr-4'>
                                <p>Additional Experience Total:</p>
                                <b>${item.f_additionalTotal?.toFixed(2)}</b>
                            </div> 
                        ): ''
                    }

                    
                    <div className='d-flex justify-content-end pr-4'>
                        <p>Convenience Fee:</p>
                        <b>${item.f_convenienceFee?.toFixed(2)}</b>
                    </div>
                    <div className='d-flex justify-content-end pr-4'>
                        <p>Extra Discount:</p>
                        <b>${item.f_discountPrice?.toFixed(2)}</b>
                    </div>

                    <div className='d-flex justify-content-end pr-4'>
                        <p>Taxes:</p>
                        <b>${item.f_taxPrice?.toFixed(2)}</b>
                    </div>


                    {
                        item._additionalDiscount ?
                            <div className='d-flex justify-content-end pr-4'>
                                <p>Additional Discount:</p>
                                <b>-${item._additionalDiscount?.toFixed(2)}</b>
                            </div>: ''
                    }


                    {
                        item.f_siblingDiscount ? (
                            <div className="d-flex justify-content-end pr-4">
                                    <p>Sibling Discount:</p><b>${item.f_siblingDiscount?.toFixed(2)}</b>
                            </div>
                        ) : ''
                    }

                    {
                        item.f_multiProgramDiscount ? (
                            <div className="d-flex justify-content-end pr-4">
                                <p>Multi Programs Discount:</p><b>${item.f_multiProgramDiscount?.toFixed(2)}</b>
                            </div>
                        ) : ''
                    }

                  
                    {
                        item.campers.map(_camper => (
                            <>
                                {_camper?.selectedProduct?.length > 0 &&  _camper?.selectedProduct?.map((_product, i) => (

                                        <>
                                            <div key={_product.itemId} className="d-flex justify-content-end pr-4">
                                                <p>{_product.title} ( {_product.count} × ${_product.price} ):</p><b>${(_product.count * _product.price).toFixed(2)}</b>
                                            </div>
                                                <>
                                                    {_product?.subItems?.length > 0 &&
                                                        _product?.subItems?.map((si, j) => 
                                                            <div 
                                                                key={`${_product}${i}${j}`} className="d-flex justify-content-end pr-4">
                                                                    <br/>
                                                                    <p>{si.title} ( {si.count} × ${si.price} ):</p><b>${(si.count * si.price).toFixed(2)}</b>
                                                            </div>
                                                        ) 
                                                    }
                                                </>
                                        </>
                                ))}

                            </>
                        ))
                    }

                </div>
            ))}
    

            {
                subtotal ? <div className="c_bor_b">
                <h4>Coupons & Rewards</h4>
                <p><small>If you have a Coupon, or Rewards Code enter it here to redeem.</small></p>
                <div className="d-flex justify-content-end align-items-center pr-4">
                    <label className="m-0">Select Coupon:</label>
                    <div className="position-relative">
                    <ReactSelect
                        className="form-control coupons_list ml-3"
                        style={{width: '300px'}}
                        placeholder="Select Coupon Code"
                        classNamePrefix="cm_select"
                        value={couponCode}
                        onChange={e => (setCouponCode(e), setCouponCodeErr(''))}
                        options={couponList.map(item => ({label: item, value: item}))} />

                        {/* <input
                            type="text"
                            value={couponCode}
                            onChange={e => (setCouponCode(e.target.value), setCouponCodeErr(''))}
                            placeholder="Enter Coupon Code"
                            className="form-control w-auto ml-2 mr-2" /> */}
                        {couponCodeErr ? <span className="cm_err_msg2">{couponCodeErr}</span> : ''}
                    </div>
                    <span onClick={couponCodeLoader ? null : handleCouponCodeSubmit} className={`btn btn-primary pl-5 pr-5 ${couponCodeLoader ? 'btnLoader' : ''}`}>Apply</span>
                </div>
                {couponCodeSuccess ? <p className="cm_success_msg">{couponCodeSuccess}</p> : ''}
            </div> : ''
            }
            {
                        showInsurance && !isInsuranceStateSelected ? <div className='row mb-4 mt-4'>
                            <div className='col-md-8'>
                                <p>Please select state for insurance</p>
                            </div>
                            <div className='col-md-4'>
                                <select
                                    value={insuranceState}
                                    onChange={e => (setInsuranceState(e.target.value), setInsuranceRender(true))}
                                    className={`form-control`} >

                                    <option value="">Select State/Province</option>   
                                        {provinceList.map((item, index) => (
                                                <option value={item.value} key={index}>
                                                {item.value} - {item.label}
                                                </option>
                                            ))}
                                </select> 
                            </div>
                        </div> : ''
                    }     
                        {
                insuranceComponentDelay && showInsurance && insuranceRender && insuranceState.length === 2 && userEmail ? 
                <div className='c_bor_b' style={{display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center'}}>
                    <RegistrationCancellation 
                            updateInsuranceQuotes ={updateInsuranceQuotes} 
                            allPrograms={allPrograms} 
                            camperList={camperList} 
                            insuranceState={insuranceState} 
                            userEmail={userEmail} 
                            userName={userName} 
                            userLastName={userLastName}
                            billingDetail={billingDetail}  
                    />
                    <GapMedicalInsurance
                        updateInsuranceQuotes ={updateInsuranceQuotes} 
                        allPrograms={allPrograms} 
                        camperList={camperList} 
                        insuranceState={insuranceState}
                        userId={userId} 
                        userEmail={userEmail} 
                        userName={userName} 
                        userLastName={userLastName} 
                        billingDetail={billingDetail}
                    />
                    
                </div> : ''
            }




                


                <div className="c_bor_b">
                    <h4>Payment Method </h4>
                    <div className="d-flex justify-content-end pr-4">
                        <p>Subtotal:</p><b> ${subtotal.toFixed(2)}</b>
                    </div>
                    {
                        couponMoney ? (
                            <div className="d-flex justify-content-end pr-4">
                                <p>Coupon Applied:</p><b>${couponMoney.toFixed(2)}</b>
                            </div>
                        ) : ''
                    }


                <Fragment>
                    {acceptedInsuranceQuotes?.map((q, i) => (
                        <div key={i} className="d-flex justify-content-end pr-4">
                            <p>{q.type === 'gap-medical' ? 'Gap Medical Insurance' : 'Registration Insurance'}({q?.eventName}):</p><b> ${(q.total / 100).toFixed(2)}</b>
                        </div>
                        ))}
                </Fragment>
                </div>


                

                <div className="c_bor_b">
                    <div className={`d-flex justify-content-end pr-4`}>
                        <p>Total to Pay:</p>
                        <b>${(totalToPay + insuranceTotal).toFixed(2)}</b>
                    </div>

                    <div className={`d-flex justify-content-end pr-4`}>
                        <div className={`form-group`}>
                            <label>Final Amount</label>
                            <CustomNumber
                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                change={e => handleActualTotalPayAmount(e)}
                                value={actualTotalPayAmount}
                                min={0}
                                precision={actualTotalPayAmount ? 2 : 0}
                                pHolder=""
                            />
                        </div>
                    </div>
                    
                    {  (!confirmActualTotalPayAmount ) && isDifferentTotalPayAmount() && 
                            <div className={`d-flex justify-content-end pr-4`}>
                            <p>Are you sure this is final payment? <span className='btn btn-secondary btn-sm' onClick={revicePayment}>Yes</span></p>
                        </div> 
                    }
                    {
                      payNote &&  isDifferentTotalPayAmount() ? <div className={`d-flex justify-content-end pr-4`}>
                        <p className="color_red"><b>Note:</b> The amount entered is different than the total amount.</p>
                    </div> : ''
                    }
                    { payNote && isDifferentTotalPayAmount() && 
                        <div className={`d-flex justify-content-end pr-4`}>
                            <div className={`form-group`}>
                                <span><small>Please enter a payment change reason.</small></span>
                                <textarea
                                    type='text'
                                    placeholder='Please enter ....'
                                    className='form-control'
                                    value={finalPayReason}
                                    onChange={handlePayChangeReaon}
                                />

                                {finalPayReasonErr ? <h6 className='cm_err mt-3'>{finalPayReasonErr}</h6> : ''}
                            </div>
                        </div>
                    }
                    

                    
                    <h4 className="mb-4">Billing Information</h4>
                </div>

            
            {
                totalToPay > 0 ? (
                    <Fragment>
                        <h3>Select a Payment Option</h3>

                        <div className="row">

                            {
                                creditCardRate.status == 1 ? (
                                    <div className="col-md-4">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 1 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(1), setPaymentMethodErr(''))}>

                                            <h4>Credit Card / Debit Card</h4>
                                            <h2>${getWithTaxPrice(creditCardRate, totalToPay )}</h2>
                                            {insuranceTotal > 0 && <p>Insurance Charges : ${getInsuranceWithTaxPrice(creditCardRate,insuranceTotal)}</p>}
                                            <p>Payment Includes {creditCardRate.percentage}% + {`$${creditCardRate.taxRate}`} Processing Charge</p>
                                        </div>
                                    </div>
                                ) : ''
                            }



                            {
                                achTransferRate.status == 1 ? (
                                    <div className="col-md-4">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 3 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(3), setPaymentMethodErr(''))}>

                                            <h4>Internet Banking</h4>
                                            <h2>${getWithTaxPrice(achTransferRate, (totalToPay))}</h2>
                                            {insuranceTotal > 0 && <p>Insurance Charges : ${getInsuranceWithTaxPrice(achTransferRate,insuranceTotal)}</p>}
                                            <p>Payment Includes {achTransferRate.percentage}% + {`$${achTransferRate.taxRate}`} Processing Charge</p>
                                        </div>
                                    </div>
                                ) : ''
                            }

                            

                            {
                                byCheque.status == 1 ? (
                                    <div className="col-md-4">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 4 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(4), setPaymentMethodErr(''))}>
                                            <h4>Check</h4>
                                            <h2>${getWithTaxPrice(byCheque, totalToPay)}</h2>
                                            {insuranceTotal > 0 && <p>Insurance Charges : ${getInsuranceWithTaxPrice(byCheque,insuranceTotal)}</p>}
                                            <p>Payment Includes {`$${byCheque.taxRate}`} Processing Charge</p>

                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Payment will be made via mail or in person. Insurance coverages are not available if paying by check</Tooltip>}>
                                                    <span className='my_tooltip'><InformationIcon /></span>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                ) : ''
                            }



                            {
                                hasSplitOption(programStartDate, splitPaymentRate.noOfTimesPayment) && splitPaymentRate.status == 1 && splitPaymentRate.noOfTimesPayment > 1 ? (
                                    <div className="col-md-4">
                                    <div
                                        className={`payment-gateway-card ${paymentMethod == 5 ? 'active' : ''}`}
                                        onClick={() => (setPaymentMethod(5), setPaymentMethodErr(''))}>

                                            <h4>Split Payment Credit Card</h4>
                                            <h2>${getSplitPrice(splitPaymentRate, totalToPay)}</h2>
                                            <p><b>{splitPaymentRate.noOfTimesPayment}</b> Payments</p>
                                            {insuranceTotal > 0 && <p>Insurance Charges : ${getInsuranceWithTaxPrice(splitPaymentRate,insuranceTotal)}</p>}
                                            <p>Payment Includes {splitPaymentRate.percentage}% + {`$${splitPaymentRate.taxRate}`} Processing Charge</p>
                                            
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Card automatically charged 30 days later for subseqent payments</Tooltip>}>
                                                    <span className='my_tooltip'><InformationIcon /></span>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                ) : ''
                            }



                            <div className="col-md-4">
                                <div className={`payment-gateway-card ${paymentMethod == 6 ? 'active' : ''}`}
                                    onClick={() => (setPaymentMethod(6), setPaymentMethodErr(''))}>
                                    <h4>Pay Later</h4>
                                    <h2>${getWithTaxPrice(creditCardRate, (totalToPay))}</h2>
                                    {insuranceTotal > 0 && <p>Insurance Charges : ${getInsuranceWithTaxPrice(creditCardRate,insuranceTotal)}</p>}
                                    <p>Payment Includes {creditCardRate.percentage}% + {`$${creditCardRate.taxRate}`} Processing Charge</p>
                                </div>
                            </div>

        
                        </div>


                        {paymentMethodErr ? <p className="color_red">{paymentMethodErr}</p> : ''}


                        {
                            (paymentMethod == 6) ? (
                                <Fragment>
                                    <div className='row'>
                                        <div className={`col-md-4 form-group`}>
                                            <label>Current Payment Amount</label>
                                            <CustomNumber
                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                change={e => setRightNowAmount(e)}
                                                value={rightNowAmount}
                                                min={0}
                                                precision={rightNowAmount ? 2 : 0}
                                                pHolder=""
                                            />
                                        </div>
                                    </div>
                                        
                                </Fragment>
                            ) : ''
                        }



                        {
                            (paymentMethod == 1 || paymentMethod == 5 || (paymentMethod == 6 && actualTotalPayAmount > 0)) ? (
                                <Fragment>
                                    <ul className="card_list">
                                        {cardList.length ? cardList.map(item => (
                                                <li key={item.signature} className={customerPaymentProfileId === item.card.token ? 'active' : ''}>
                                                <div className="check_box_wrapper cm_dark_check pt-1">
                                                    <input
                                                        checked={customerPaymentProfileId === item.card.token}
                                                        onChange={() => (setCustomerPaymentProfileId(item.card.token), setCustomerPaymentProfileIdError(''))}
                                                        type="radio"
                                                        name="gender"
                                                        value="male" />
                                                    <span className="check_box"><span></span></span>
                                                </div>
                                                <div className="p_card_img ml-4">
                                                    <img src={getCardTypeImage(item?.card?.brand)} alt={item?.card?.brand || "Visa"}/>
                                                </div>
                                                <p>********{item.card.acct_last_four}</p>
                                            </li>

                                            )) : ''
                                        }
                                    </ul>

                                    {customerPaymentProfileIdError ? <p className="color_red text-center">{customerPaymentProfileIdError}</p> : ''}


                                {
                                    isNewCard ? (
                                        <div className="card_inputs add_card_form">
                                            <div className="form-group">
                                                <label>Card Number</label>
                                                {/* <InputMask
                                                    className={`form-control ${cardNoErr ? 'is-invalid' : ''}`}
                                                    name="cardNumber"
                                                    placeholder="XXXX XXXX XXXX XXXX"
                                                    mask="9999 9999 9999 9999"
                                                    onKeyPress={() => setCardNoErr('')}
                                                    onChange={e => setCardNo(e.target.value)} /> */}

                                                    <input
                                                        type="text"
                                                        className={`form-control ${cardNoErr ? 'is-invalid' : ''}`}
                                                        name="cardNumber"
                                                        placeholder="XXXX XXXX XXXX XXXX"
                                                        value={cardNo}
                                                        maxLength={19}
                                                        onChange={handleCardNumberChange} />

                                                    {cardNoErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cardNoErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            <div className="row">
                                                <div className="col form-group">
                                                    <label>Expiry Date</label>
                                                    <DatePicker
                                                        className={`form-control ${expDateErr ? 'is-invalid' : ''}`}
                                                        format="MM/YY"
                                                        picker="month"
                                                        placeholder="MM/YY"
                                                        onKeyPress={() => setExpDateErr('')}
                                                        onChange={e => (setMonth(`${addZero(e._d.getMonth() + 1)}`), setYear(`${e._d.getFullYear().toString()}`), setExpDate(`${addZero(e._d.getMonth() + 1)}${e._d.getFullYear().toString()}`), setExpDateErr(''))} />

                                                    {expDateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{expDateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>
                                                <div className="col form-group">
                                                    <label>CVC</label>
                                                    {/* <InputMask
                                                        className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                        placeholder="XXX"
                                                        mask="999"
                                                        onKeyPress={() => setCvcErr('')}
                                                        onChange={e => setCvc(e.target.value)} /> */}

                                                    <input
                                                        type="text"
                                                        className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                        name="cvc"
                                                        placeholder="XXX"
                                                        value={cvc}
                                                        maxLength={4}
                                                        onChange={handleCvcChange} />

                                                        {cvcErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cvcErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label>Cardholder First Name</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${firstNameErr ? 'is-invalid' : ''}`}
                                                    placeholder="Cardholder First Name"
                                                    onKeyPress={() => setFirstNameErr('')}
                                                    onChange={e => setFirstName(e.target.value)} />

                                                    {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            <div className="form-group">
                                                <label>Cardholder Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cardholder Last Name"
                                                    onChange={e => setLastName(e.target.value)} />
                                            </div>

                                            <div className="form-group">
                                                <label>Zip Code</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${zipCodeErr ? 'is-invalid' : ''}`}
                                                    placeholder="Zip Code"
                                                    onChange={e => (setZipCode(e.target.value), setZipCodeErr(''))} />

                                                    {zipCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{zipCodeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            {resErr ? <h6 className="res_error">{resErr}</h6> : ''}

                                            <div className="d-flex justify-content-between">
                                            <span className="btn btn-info rounded-0 w-100 mr-2">Cancel</span>
                                            <span onClick={addCardBtnLoader ? null : handleAddCardSubmint} className={`btn btn-primary rounded-0 w-100  ${addCardBtnLoader ? 'btnLoader' : ''}`}>Add</span>
                                            </div>


                                            </div>
                                    ) : <button className="btn btn-primary mb-3" onClick={() => setIsNewCard(true)}>Add New Card</button>
                                } 
                            </Fragment>
                            ) : ''
                        }





{    
                            paymentMethod == 3 ? (
                                <Fragment>
                                    <div className="card_inputs add_card_form">

                                        <div className="form-group">
                                            <label>Select Account Type</label>
                                            <Select
                                                className={`form-control ${bankAccountTypeErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Account Type"
                                                onChange={e => (setBankAccountType(e), setBankAccountTypeErr(''))}>

                                                <Option value="checking">Checking</Option>
                                                <Option value="savings">Savings</Option>
                                                <Option value="businessChecking">business Checking</Option>

                                            </Select>

                                            {bankAccountTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountTypeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>
                                        <div className="form-group">
                                            <label>Routing Number</label>
                                            <input
                                                type='number'
                                                className={`form-control ${routingNumberErr ? 'is-invalid' : ''}`}
                                                value={routingNumber}
                                                placeholder="Routing Number"
                                                name="routingNumber"
                                                onChange={e => (setRoutingNumber(e.target.value), setRoutingNumberErr(''))} />

                                            {routingNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{routingNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>


                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <input
                                                type='number'
                                                name="bankAccountNum"
                                                className={`form-control ${bankAccountNumErr ? 'is-invalid' : ''}`}
                                                value={bankAccountNum}
                                                placeholder="Account Number"
                                                onChange={e => (setBankAccountNum(e.target.value), setBankAccountNumErr(''))} />

                                            {bankAccountNumErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountNumErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>





                                        <div className="form-group">
                                            <label>Account Holder Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${nameOnAccountErr ? 'is-invalid' : ''}`}
                                                name="nameOnAccount"
                                                value={nameOnAccount}
                                                onChange={e => (setNameOnAccount(e.target.value), setNameOnAccountErr(''))}
                                                placeholder="Account Holder Name" />

                                            {nameOnAccountErr? <OverlayTrigger placement="left" overlay={<Tooltip>{nameOnAccountErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        </div>   
                            </Fragment>
                            ) : ''
                        }

                    </Fragment>
                ) : ''
            }


            <div className='d-flex justify-content-between mt-5'>
                <span onClick={handleBack} className='btn btn-dark'>Back</span>
                <div>{resErr ? <span className='cm_error text-left'>{resErr}</span> : ''}</div>
                {slotAvailableMsg ? <p className="color_red">{slotAvailableMsg}</p> : ''}
                {isSlotAvailable ? <span className={`btn btn-primary rounded-0  btn_anim ${btnLoader ? 'show_anim' : ''}`} onClick={() => btnLoader ? null : handleCreateBooking()}>Submit Registration</span> : ''}
                
            </div>
        </div>
    )
}


export default PaymentInformationCompoant;