import React, { useState, useEffect, Fragment } from 'react';
import {  deductPaylaterAmountService, getPaidAmountRecipt, getCombinedPendingAmountList } from '../../services/onlineStoreService';
import moment from 'moment';
import { DatePicker, Select, notification, Empty } from 'antd';
import { AES } from 'crypto-js';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import NumericInput from 'react-numeric-input';

import { createCustomerCardService, deleteCardService, getCustomerCardsService, markPrimaryCard } from '../../services/programService';
import { getCampDetailService } from '../../services/userServices';
import { ProgramLoader } from '../reuse/SVG';
import settings from '../../config/settings';
import { resHandle } from '../../helper';
import PaginatedComponent from '../common/Pagination/PaginationComponent';
import PaymentCard from './PaymentCard';

const { Option } = Select;

const addZero = num => {
    if( num.toString().length < 2 )
    return "0" + num;
    return num.toString();
}



const getCardTypeName = type => {
    switch (type) {
        case 'American Express':
            return 'AmericanExpress';
            break;
        case 'Visa Electron':
        case 'visa':
            return 'Visa';
            break;
        case 'mastercard':
        case 'Mastercard':
            return 'MasterCard';
            break;
        case 'discover':
        case 'Discover':
            return 'Discover';
            break;
        case 'china_unionpay':
        case 'ChinaUnionpay':
            return 'Unionpay';
            break;
        case 'Diners Club':
            return 'DinersClub';
            break;
        default:
            return 'Visa';
    }

}
const getWithTaxPrice = (obj, amount) => {
    let withTax = 0
    if(obj.percentage){
        withTax = amount + ((amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        withTax = amount + obj.taxRate;
    }
    return withTax.toFixed(2);
}



const PaymentHistory = props => {
    const [loader, setLoader] = useState(false);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [paymentModal, setPaymentModal] = useState(false);
    const [activePaymentObject, setActivePaymentObject] = useState({});
    const [amount, setAmount] = useState(0);
    const [emiId, setEmiId] = useState('');
    const [emiObj, setEmiObj] = useState('');
    const [pendingPaymentType, setPendingPaymentType] = useState(1);


    const [creditCardRate, setCreditCardRate] = useState({});
    const [achTransferRate, setAchTransferRate] = useState({});
    const [byCheque, setByCheque] = useState({});
    const [splitPaymentRate, setSplitPaymentRate] = useState({});

    const [paymentMethod, setPaymentMethod] = useState(0);
    const [paymentMethodErr, setPaymentMethodErr] = useState('');
    const [isNewCard, setIsNewCard] = useState(false);
    const [cardList, setCardList] = useState([]);


    const [addCardBtnLoader, setAddCardBtnLoader] = useState(false);
    const [cardNo, setCardNo] = useState('');
    const [cardNoErr, setCardNoErr] = useState('');
    const [expDate, setExpDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [expDateErr, setExpDateErr] = useState('');
    const [cvc, setCvc] = useState('');
    const [cvcErr, setCvcErr] = useState('');
    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');



    const [customerProfileId, setCustomerProfileId] = useState([]);
    const [customerPaymentProfileId, setCustomerPaymentProfileId] = useState('');
    const [customerPaymentProfileIdErr, setCustomerPaymentProfileIdErr] = useState('');
    const [customerPaymentProfileIdError, setCustomerPaymentProfileIdError] = useState('');

    const [billingDetail, setBillingDetail] = useState({});
    const [resErr, setResErr] = useState('');
    const [allCampCodes, setAllCampCodes] = useState('');

    const [bankAccountType, setBankAccountType] = useState('');
    const [bankAccountNum, setBankAccountNum] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [nameOnAccount, setNameOnAccount] = useState('');
    const [bankAccountTypeErr, setBankAccountTypeErr] = useState('');
    const [bankAccountNumErr, setBankAccountNumErr] = useState('');
    const [routingNumberErr, setRoutingNumberErr] = useState('');
    const [nameOnAccountErr, setNameOnAccountErr] = useState('');

    const [zipCode, setZipCode] = useState('');
    const [zipCodeErr, setZipCodeErr] = useState('');
    const [primaryCardId,setPrimaryCardId] = useState('');
    const [isDeletePopup,setIsDeletePopup] = useState(false);
    const [deleteCardToken,setDeleteCardToken] = useState('');
    const [addPrimary, setAddPrimary] = useState(false);
    const [isSwitchPrimaryCard,setIsSwitchPrimaryCard] = useState(false);
    const [holdPrimaryId, setHoldPrimaryId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [paymentOptionType, setPaymentOptionType] = useState(1);
    const [activeProductPaymentObject, setActiveProductPaymentObject] = useState({});
    const [productAmount, setProductAmount] = useState(0);



    const getPaymentRecipt = () => {
        setIsLoading(true)
        let query = '';
        if(props?.data?._id){
            query = `?page=${page - 1}&limit=${limit}&userId=${props?.data?._id}`
        }else{
            query = `?page=${page - 1}&limit=${limit}`
        }
        getPaidAmountRecipt(query).then(res => {
            if(res?.data?.statusCode){
                setList(res.data.responseData.result);
                setTotalCount(res.data.responseData.totalCount);
                setIsLoading(false)
            }
            
        }).catch(e  => {
            setIsLoading(false)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const getOrderList = () => {
        setIsLoading(true)
        let query = '';
        if(props?.data?._id){
            query = `?page=${page - 1}&limit=${limit}&userId=${props?.data?._id}`
        }else{
            query = `?page=${page - 1}&limit=${limit}`
        }
        getCombinedPendingAmountList(query).then(res => {
            if(res?.data?.statusCode){
                setList(res.data.responseData.result);
                setTotalCount(res.data.responseData.totalCount);
                setIsLoading(false)
            }      
        }).catch(e  => {
            setIsLoading(false)
        }).finally(() => {
            setIsLoading(false)
        })
    }


    useEffect(() => {
        if(pendingPaymentType ===3 ){
            getPaymentRecipt();
        }else{
            getOrderList();

        }
    }, [page, pendingPaymentType, props?.data?._id])
    


    const handleCloseModal = () => {
        setPaymentModal(false);
        setActivePaymentObject({});
        setAmount(0);
        setProductAmount(0);
        setPaymentOptionType(1);
        setActiveProductPaymentObject({});
    }

    const getTaxs = () => {
        getCampDetailService(`?campCode=${activePaymentObject.campCode}`).then(res => {
            if(res?.data?.statusCode){
              let _data = res.data.responseData.result;
              setCreditCardRate(_data.creditCardRate || {});
              setAchTransferRate(_data.achTransferRate || {});
              setByCheque(_data.byCheque || {});
              setSplitPaymentRate(_data.splitPaymentRate || {});
            }
        })
    }


    const getCards = () => {
        setLoader(true);
        setCardList([]);
        setHoldPrimaryId('');
        setPrimaryCardId('');
        let query = `?isWeb=1&campCode=${activePaymentObject.campCode}`
        getCustomerCardsService(query).then(res => {
            if (res.data.statusCode) {
                if(res.data.responseData.result.length){
                    setCardList(res.data.responseData.result ? [...res.data.responseData.result] : []);
                    setCustomerProfileId(res.data.responseData.result ? res.data.responseData.result[0].card.signature : '');
                    setLoader(false)
                }else{
                    setLoader(false)
                    setIsNewCard(true);
                }
            } else {
                setLoader(false)
                setCardList([]);
            }
        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            setLoader(false)
        })
    }

    useEffect(() => {
        if(props?.allCampCodes?.length && props?.allCampCodes?.length){
            getCards();
        }
      
    }, [props.isNewCardAdd, props.allCampCodes])

    useEffect(() => {
        if(activePaymentObject.campCode){
            getTaxs();
            getCards();
        }

    }, [activePaymentObject.campCode])




    const handleValidate = () => {
        let validate = true;

        if(amount > 0){
            if(paymentMethod == 0){
                validate = false;
                setPaymentMethodErr('Please select payment method');
            }
    
            if(paymentMethod == 1){
                if(customerPaymentProfileId == '' || customerPaymentProfileId == 'undefined' || customerPaymentProfileId == null){
                    validate = false;
                    setCustomerPaymentProfileIdError('Please select card')
    
                }
            }


            if(paymentMethod == 3) {

                if(bankAccountType == '' || bankAccountType == 'undefined' || bankAccountType == null){
                    validate = false;
                    setBankAccountTypeErr('Account type is required')
                }

                if(bankAccountNum == '' || bankAccountNum == 'undefined' || bankAccountNum == null){
                    validate = false;
                    setBankAccountNumErr('Account number is required')
                }

                if(routingNumber == '' || routingNumber == 'undefined' || routingNumber == null){
                    validate = false;
                    setRoutingNumberErr('Routing number is required')
                }

                if(nameOnAccount == '' || nameOnAccount == 'undefined' || nameOnAccount == null){
                    validate = false;
                    setNameOnAccountErr('Account holder name is required')
                }

            }
        }

       

        return validate;
    }




    const handleAddCardSubmint = () => {
        let validate = true;
        
        setResErr('');

        if(cardNo == ''){
            setCardNoErr('card number error');
            validate = false;
        }else if (!(cardNo.length == 18 || cardNo.length == 19)) {
            setCardNoErr('card number lenth error');
            validate = false;    
        }else if(cardNo.includes('_')){
            setCardNoErr('card number lenth error');
            validate = false;    
        }

        if(expDate == ''){
            setExpDateErr('card expired date error');
            validate = false;
        }

        if(zipCode == ''){
            setZipCodeErr('zip code error');
            validate = false;
        }

        if(cvc == ''){
            setCvcErr('cvc error');
            validate = false;
        }else if(cvc.includes('_')){
            setCvcErr('cvc lenth error');
            validate = false;    
        }

        if(firstName == ''){
            setFirstNameErr('first name error');
            validate = false;
        }else if(firstName.length > 50){
            setFirstNameErr('First name is to large max limit 50');
            validate = false;
        }

        if(validate){

            setAddCardBtnLoader(true);

            let _cardNo = cardNo.replace(/\s/g,'');
            const encrypted_cardNo = AES.encrypt(_cardNo, settings.api.mySecretKey).toString();
            const encrypted_cvc = AES.encrypt(cvc, settings.api.mySecretKey).toString();
            

          

            let params = {
                cardNo: encrypted_cardNo,
                expDate,
                month,
                year,
                cvc: encrypted_cvc,
                firstName,
                lastName,
                email: props.userProfile.email,
                userId: props.userProfile._id,
                isWeb: 1,
                campCode: activePaymentObject.campCode,
                zipCode,
                primary:String(addPrimary),
                
                
            }

           
            createCustomerCardService(params).then(res => {
                setAddCardBtnLoader(false);
                if(res?.data?.statusCode){
                    setCardNo('');
                    setExpDate('');
                    setCvc('');
                    setFirstName('');
                    setLastName('');
                    setIsNewCard(false);
                    getCards();                  
                }else{
                    setResErr(res.data.error.responseMessage)
                }
                
            })
        }
    }





    const handleCreateBooking = () => {

        if (handleValidate()) {
            setBtnLoader(true);
            let params = {};


            

            if (paymentMethod == 1) {
                params = {
                    bookingId: activePaymentObject._id,
                    authPayment: {
                        paymentType: paymentMethod,
                        customerProfileId,
                        customerPaymentProfileId,
                        token: customerPaymentProfileId
                    },
                    payment: {
                        method: "Credit Card",
                        amount: paymentOptionType >= 2 ? getWithTaxPrice(creditCardRate,productAmount) :getWithTaxPrice(creditCardRate, amount)
                    },
                    campCode: activePaymentObject.campCode
                }

                if(emiObj._id){
               

                    params.emiId = emiObj._id;
                    params.emiAmount = amount?.toFixed(2);
    
                    if(emiObj.emiAmount == amount){
                        params.isFullPayment = true;
                    }
                }else{
                    params.remainingAmount = activePaymentObject.remainingAmount - amount;
                }
            }


            if(paymentMethod == 3) {

                const __routing_number = AES.encrypt(routingNumber, settings.api.mySecretKey).toString();
                const __bankAccountNum = AES.encrypt(bankAccountNum, settings.api.mySecretKey).toString();

                params = {
                    isWeb: 1,
                    bookingId: activePaymentObject._id,
                    authPayment: {
                        account_owner_name: nameOnAccount,
                        routing_number: __routing_number,
                        account_number: __bankAccountNum,
                        account_type: bankAccountType,
                        account_owner_type: 'individual',
                        country: 'US',
                        currency: 'usd',
                        paymentType: 4
                    },
                    payment: {
                        method: 'Internet Banking',
                        amount: paymentOptionType >= 2 ? getWithTaxPrice(achTransferRate,productAmount) : getWithTaxPrice(achTransferRate, amount)
                    },
                    campCode: activePaymentObject.campCode
                }

                if(emiObj._id){
               

                    params.emiId = emiObj._id;
                    params.emiAmount = amount?.toFixed(2);
    
                    if(emiObj.emiAmount == amount){
                        params.isFullPayment = true;
                    }
                }else{
                    params.remainingAmount = activePaymentObject.remainingAmount - amount;
                }
        
            }

            if(paymentOptionType >= 2){ //pending payment type ? two wont be there because there isn't any setter for 2
                params.purchaseId = activeProductPaymentObject._id; //
                params.campCode = activePaymentObject.campCode;
                params.remainingAmount = 0;
                params.type = 2;

            }else{
                params.type = 1;
            }


            
    
            deductPaylaterAmountService(params).then(res => {
                let { status, data } = resHandle(res);
                setBtnLoader(false);
                if(status){
                    handleCloseModal();//
                    getOrderList();
                    notification.open({
                        message: 'Payments',
                        description:
                            'Payment create successfully',
                    });
                    
                } else {
                    setErrorMsg(res.data.error.errors.message || res.data.error.responseMessage);
                }
            })
        }  
        
    }


    useEffect(() => {

        if(pendingPaymentType == 1){
            if(activePaymentObject?.isEmi?.allEmiRemains?.length){
                let _emiData = activePaymentObject?.isEmi?.allEmiRemains?.find(i => (i.status === 2 || i.status === 3));
                if(_emiData?._id){
                    setEmiObj(_emiData);
                    setAmount(_emiData.emiAmount)
                }
            }
        }else{
            setAmount(activePaymentObject.remainingAmount)
        }

        
    }, [activePaymentObject])


    const handleCardNumberChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCardNo(formattedNumber);
        setCardNoErr('');
    };

    const handleCvcChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCvc(formattedNumber);
        setCvcErr('');
      };


      
      const handleDeleteCard = (cardId) => {
        setIsDeletePopup(true); 
        setDeleteCardToken(cardId)
      }
      const deleteCardAction = () => {
        const params = {
            userId:props.userProfile._id,
            cardToken :deleteCardToken,
        }
        //added api call to remove the card using card id. 
        deleteCardService(params).then( res => {
            if(res.status){
                getCards()
                setCardList(cardList.filter(card =>card.card.id !== deleteCardToken));
                setIsDeletePopup(false)
            }
        }).catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsDeletePopup(false)
        }
        )
        
      }
      const handlePrimaryCardAction = () => {
        const params = {
            userId: props.userProfile._id,
            cardToken :holdPrimaryId,
        }
        markPrimaryCard(params).then( res => {
            if(res.status){
                setIsSwitchPrimaryCard(false)
                getCards();
                setPrimaryCardId(holdPrimaryId);
            }
        }).catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsSwitchPrimaryCard(false)
        }
        )
      }
      const handlePrimaryCard = (id) => {
            setHoldPrimaryId(id)
            setIsSwitchPrimaryCard(true)
      }

      const handleProductPayment = (i) => {
        setPaymentOptionType(i + 2);
        setActiveProductPaymentObject(activePaymentObject?.purchaseItems[i])
        setProductAmount(activePaymentObject?.purchaseItems[i]?.remainingAmount)
      }

      useEffect(() => {
        if(paymentModal){
            if(!activePaymentObject?.remainingAmount){
                const itemIndex  = activePaymentObject?.purchaseItems?.findIndex( (item , i ) => {
                    if(item?.purchaseType === 1 && item?.remainingAmount > 0){
                       return true; 
                    }else{
                        return false;
                    } 
                })
                if(itemIndex >= 0 ){
                    setPaymentOptionType(itemIndex + 2);
                    setActiveProductPaymentObject(activePaymentObject?.purchaseItems[itemIndex]);
                    setProductAmount(activePaymentObject?.purchaseItems[itemIndex]?.remainingAmount);
                }
            }
        }
      }, [paymentModal])
    
    return(
        <div className="content_wrapper booking_page">

<Modal
                    show={isDeletePopup || isSwitchPrimaryCard}
                    animation={false}
                    dialogClassName="folder_model"
                    size="lg"
                    centered
                >
                {isSwitchPrimaryCard && 
                    <Modal.Body>
                    <span className="modal_close" onClick={() => { setIsSwitchPrimaryCard(false) }}><i className="icon-cross" /></span>
                    <div className="text-center p-5 pickup_modal_body">
                        <p>Are you sure you want to change your primary card ?</p>
                    </div>
                    <div className="text-center p-5">
                        <button onClick={() => { setPrimaryCardId(''); setIsSwitchPrimaryCard(false) }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                        <button onClick={handlePrimaryCardAction} style={{ width: '120px' }} className="btn btn-primary ml-3">Yes</button>
                    </div>
                    </Modal.Body>
                
                }
                  { isDeletePopup && 
                    <Modal.Body>
                    <span className="modal_close" onClick={() => { setIsDeletePopup(false) }}><i className="icon-cross" /></span>
                    <div className="text-center p-5 pickup_modal_body">
                        <p>Are you sure you want to remove this card ?</p>
                    </div>
                    <div className="text-center p-5">
                        <button onClick={() => { setDeleteCardToken(''); setIsDeletePopup(false) }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                        <button onClick={deleteCardAction} style={{ width: '120px' }} className="btn btn-primary ml-3">Yes</button>
                    </div>
                    </Modal.Body>
                
                }
                </Modal>
            <Modal
                size="lg"
                show={paymentModal}
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>Payments</Modal.Title>
                    <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>


                {
                    activePaymentObject?.isEmi?.allEmiRemains?.length ? (
                        <Fragment>
                            <p><b>Select Amount:</b></p>
                            {
                                activePaymentObject?.isEmi.allEmiRemains.filter(mmi => mmi.status != 1).map(emi => (
                                    <div onClick={() => (setEmiObj(emi), setAmount(emi.emiAmount))} key={emi._id} className={`d-flex justify-content-between align-items-center emi_chip ${emi._id == emiObj._id ? 'active' : ''}`}>
                                        <div><date>Date: {moment(emi.emiDate).format('MMM DD, YYYY')}</date></div> -
                                        <div>Amount: <b>${emi?.emiAmount?.toFixed(2)}</b></div>
                                    </div>
                                ))
                            }
                        </Fragment>
                    ) : (
                        <Fragment>
                         <h4>Remaining Amount: ${paymentOptionType >= 2 ? productAmount?.toFixed(2) : activePaymentObject?.remainingAmount?.toFixed(2)}</h4>
                            <div className="form-group">
                                    <p className="a_label">Please Select Payment Type</p>
                                    <div className='inbox_tabs'>
                                        <span className={paymentOptionType === 1 ? 'active' : ''} onClick={() => setPaymentOptionType(1)}>Program</span>
            
                                        {
                                            activePaymentObject?.purchaseItems?.map( (item , i ) => 
                                                (item?.purchaseType === 1 && item?.remainingAmount > 0)  && <span className={paymentOptionType === (i+2) ? 'active' : ''} onClick={() => handleProductPayment(i)}>Products payment {i +1}</span>
                                            )
                                        }
                                    </div>
                                </div>   
                        </Fragment>
                    )
                    }
                    

                    {
                        <div className={`form-group`}>
                                                        <label>Enter Amount to Pay</label>
                                                        <NumericInput
                                                            step={0.1}
                                                            disabled={paymentOptionType >= 2}
                                                            precision={2}
                                                            value={paymentOptionType >= 2 ? productAmount:amount}
                                                            className="form-control"
                                                            min={ 0 } 
                                                            max={ 100 } 
                                                            size={ 2 } 
                                                            onChange={e => setAmount(e)}
                                                            pattern="[0-9].[0-9][0-9]" />
                                                    </div> 
                    }
                    
                   
               

                   

                    <h5>Select a Payment Option</h5>

                        <div className="row">

                            {
                                creditCardRate.status == 1 ? (
                                    <div className="col-md-6">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 1 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(1), setPaymentMethodErr(''))}>

                                            <h4>Credit Card / Debit Card</h4>
                                            <h2>${paymentOptionType >= 2 ? getWithTaxPrice(creditCardRate,productAmount) :getWithTaxPrice(creditCardRate, amount)}</h2>
                                            <p>Payment Includes {creditCardRate.percentage}% + {`$${creditCardRate.taxRate}`} Processing Charge</p>
                                        </div>
                                    </div>
                                ) : ''
                            }



                            {
                                achTransferRate.status == 1 ? (
                                    <div className="col-md-6">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 3 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(3), setPaymentMethodErr(''))}>

                                            <h4>Internet Banking</h4>
                                            <h2>${paymentOptionType >= 2 ? getWithTaxPrice(achTransferRate,productAmount) :getWithTaxPrice(achTransferRate, amount)}</h2>
                                            <p>Payment Includes {achTransferRate.percentage}% + {`$${achTransferRate.taxRate}`} Processing Charge</p>
                                        </div>
                                    </div>
                                ) : ''
                            }


        
                        </div>


                        {paymentMethodErr ? <p className="color_red">{paymentMethodErr}</p> : ''}


                



                        {
                            paymentMethod === 1 ? (
                                <Fragment>
                                    <ul className="card_list">
                                        {loader ? 
                                            <div className="program_loader"><ProgramLoader /></div>
                                            :
                                        cardList.length ? cardList.map(item => (
                                                <li key={item.signature} className={customerPaymentProfileId === item.card.token ? 'active' : ''} style={{justifyContent: 'space-between'}}>
                                                    <div style={{ display: 'flex' ,alignItems: 'center'}}>
                                                    <div className="check_box_wrapper cm_dark_check pt-1">
                                                        <input
                                                            checked={customerPaymentProfileId === item.card.token}
                                                            onChange={() => (setCustomerPaymentProfileId(item.card.token), setCustomerPaymentProfileIdErr(''))}
                                                            type="radio"
                                                            name="gender"
                                                            value="male" />
                                                        <span className="check_box"><span></span></span>
                                                    </div>
                                                    <div className="p_card_img ml-2">
                                                        <img src={item?.card?.brand ? require(`../../assets/img/${getCardTypeName(item.card.brand)}.svg`) : require(`../../assets/img/Visa.svg`)} />
                                                    </div>
                                                    <span className='primary-card-text'>********{item.card.acct_last_four}</span>                                                  
                                                    </div>
                                                    <div className='primary-card-content'>
                                                 { !(item.card?.primary) ?
                                                    <>
                                                         <div className="check_box_wrapper cm_dark_check">
                                                            <input
                                                                checked={false}
                                                                onClick={() => handlePrimaryCard(item.card.token)}
                                                                type="radio"
                                                            />
                                                            <span className="check_box"><span></span></span>
                                                        </div>
                                                        <span className='primary-card-text'>set as primary</span>
                                                    </>
                                                    :
                                                    <span className = {`primary-card-text btn-primary`}>Primary Card</span>
                                                }
                                                 <div className = ""><i onClick={() => handleDeleteCard(item.card.token)} className="icon-delete" style={ {fontSize: "24px"}}/></div>
                                                </div>
                                                </li>
                                            )) : ''
                                        }
                                    </ul>

                                    {customerPaymentProfileIdError ? <p className="color_red text-center">{customerPaymentProfileIdError}</p> : ''}


                                {
                                    isNewCard ? (
                                        <div className="card_inputs add_card_form">
                                            <div className="form-group">
                                                <label>Card Number</label>
                                                {/* <InputMask
                                                    className={`form-control ${cardNoErr ? 'is-invalid' : ''}`}
                                                    name="cardNumber"
                                                    placeholder="XXXX XXXX XXXX XXXX"
                                                    mask="9999 9999 9999 9999"
                                                    onKeyPress={() => setCardNoErr('')}
                                                    onChange={e => setCardNo(e.target.value)} /> */}

                                                <input
                                                    type="text"
                                                    className={`form-control ${cardNoErr ? 'is-invalid' : ''}`}
                                                    name="cardNumber"
                                                    placeholder="XXXX XXXX XXXX XXXX"
                                                    value={cardNo}
                                                    maxLength={19}
                                                    onChange={handleCardNumberChange} />

                                                    {cardNoErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cardNoErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            <div className="row">
                                                <div className="col form-group">
                                                    <label>Expiry Date</label>
                                                    <DatePicker
                                                        className={`form-control ${expDateErr ? 'is-invalid' : ''}`}
                                                        format="MM/YY"
                                                        picker="month"
                                                        placeholder="MM/YY"
                                                        onKeyPress={() => setExpDateErr('')}
                                                        onChange={e => (setMonth(`${addZero(e._d.getMonth() + 1)}`), setYear(`${e._d.getFullYear().toString()}`), setExpDate(`${addZero(e._d.getMonth() + 1)}${e._d.getFullYear().toString()}`), setExpDateErr(''))} />

                                                    {expDateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{expDateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>
                                                <div className="col form-group">
                                                    <label>CVC</label>
                                                    {/* <InputMask
                                                        className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                        placeholder="XXX"
                                                        mask="999"
                                                        onKeyPress={() => setCvcErr('')}
                                                        onChange={e => setCvc(e.target.value)} /> */}

                                                    <input
                                                        type="text"
                                                        className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                        name="cvc"
                                                        placeholder="XXX"
                                                        value={cvc}
                                                        maxLength={4}
                                                        onChange={handleCvcChange} />

                                                        {cvcErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cvcErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label>Cardholder First Name</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${firstNameErr ? 'is-invalid' : ''}`}
                                                    placeholder="Cardholder First Name"
                                                    onKeyPress={() => setFirstNameErr('')}
                                                    onChange={e => setFirstName(e.target.value)} />

                                                    {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            <div className="form-group">
                                                <label>Cardholder Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cardholder Last Name"
                                                    onChange={e => setLastName(e.target.value)} />
                                            </div>

                                            <div className="form-group">
                                                <label>Zip Code</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${zipCodeErr ? 'is-invalid' : ''}`}
                                                    placeholder="Zip Code"
                                                    onChange={e => (setZipCode(e.target.value), setZipCodeErr(''))} />

                                                    {zipCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{zipCodeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>
                                            <div className='form-group'>  
                                            <div className="check_box_wrapper cm_dark_check pt-1">
                                                    
                                                    <input
                                                        checked={addPrimary}
                                                        onChange={() => setAddPrimary(prev => !prev)}
                                                        type="checkbox"
                                                    />
                                                    <span className="check_box"><span></span></span>
                                                    <label>Make this card primary</label>
                                                </div>
                                            </div>

                                            {resErr ? <h6 className="res_error">{resErr}</h6> : ''}

                                            <div className="d-flex justify-content-between">
                                            <span className="btn btn-info rounded-0 w-100 mr-2">Cancel</span>
                                            <span onClick={addCardBtnLoader ? null : handleAddCardSubmint} className={`btn btn-primary rounded-0 w-100  ${addCardBtnLoader ? 'btnLoader' : ''}`}>Add</span>
                                            </div>


                                            </div>
                                    ) : <button className="btn btn-primary mb-3" onClick={() => setIsNewCard(true)}>Add New Card</button>
                                } 
                            </Fragment>
                            ) : ''
                        }





{    
                            paymentMethod == 3 ? (
                                <Fragment>
                                    <div className="card_inputs add_card_form">

                                        <div className="form-group">
                                            <label>Select Account Type</label>
                                            <Select
                                                className={`form-control ${bankAccountTypeErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Account Type"
                                                onChange={e => (setBankAccountType(e), setBankAccountTypeErr(''))}>

                                                <Option value="checking">Checking</Option>
                                                <Option value="savings">Savings</Option>
                                                <Option value="businessChecking">business Checking</Option>

                                            </Select>

                                            {bankAccountTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountTypeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>
                                        
                                        <div className="form-group">
                                            <label>Routing Number</label>
                                            <input
                                                type='number'
                                                className={`form-control ${routingNumberErr ? 'is-invalid' : ''}`}
                                                value={routingNumber}
                                                placeholder="Routing Number"
                                                name="routingNumber"
                                                onChange={e => (setRoutingNumber(e.target.value), setRoutingNumberErr(''))} />

                                            {routingNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{routingNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>

                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <input
                                                type='number'
                                                name="bankAccountNum"
                                                className={`form-control ${bankAccountNumErr ? 'is-invalid' : ''}`}
                                                value={bankAccountNum}
                                                placeholder="Account Number"
                                                onChange={e => (setBankAccountNum(e.target.value), setBankAccountNumErr(''))} />

                                            {bankAccountNumErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountNumErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>






                                        <div className="form-group">
                                            <label>Account Holder Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${nameOnAccountErr ? 'is-invalid' : ''}`}
                                                name="nameOnAccount"
                                                value={nameOnAccount}
                                                onChange={e => (setNameOnAccount(e.target.value), setNameOnAccountErr(''))}
                                                placeholder="Account Holder Name" />

                                            {nameOnAccountErr? <OverlayTrigger placement="left" overlay={<Tooltip>{nameOnAccountErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        </div>   
                            </Fragment>
                            ) : ''
                        }

                        {errorMsg ? <p className="color_red">{errorMsg}</p> : ''}
                            <div className="d-flex justify-content-between mt-5">
                                <span onClick={handleCloseModal} className="btn btn-info rounded-0 w-100 mr-2">Cancel</span>
                                <span onClick={() => btnLoader ? null : handleCreateBooking()} className={`btn btn-primary rounded-0 w-100  ${btnLoader ? 'btnLoader' : ''}`}>Pay</span>
                            </div>

                </Modal.Body>
            </Modal>




            <div className="d-flex justify-content-between align-items-center mb-4 mt-3">
                <div className=''>
                    <span onClick={() => setPendingPaymentType(1)} className={`btn btn-${pendingPaymentType == 1 ? 'primary' : 'dark'}`}>Pending Payments</span>
                    <span onClick={() => setPendingPaymentType(3)} className={`btn btn-${pendingPaymentType == 3 ? 'primary' : 'dark'} ml-3`}>Receipts</span>
                </div>
            </div>


            {list.length ?
                list.map(item => (
                        <PaymentCard
                                key = {item._id} 
                                item = {item} 
                                isLoading={isLoading} 
                                pendingPaymentType={pendingPaymentType} 
                                setActivePaymentObject={setActivePaymentObject}
                                setPaymentModal={setPaymentModal}
                        />
                )) : <div className="cm_empty"><Empty description="No Data" /></div>
            }

            {
                list.length ? 
                <div className="d-flex justify-content-center mt-5">
                    <PaginatedComponent
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={4}
                        onPageChange={setPage}/>
                </div> : ''
            }

        </div>
    )
}

const mapStateToProps = state => {
    let { userProfile, loggedIn } = state.user
    return {
        userProfile,
        loggedIn
    };
}

export default connect(mapStateToProps, null)(PaymentHistory);
